import { type FC } from 'react';
import { useState } from 'react';
import { chakra, HStack } from '@chakra-ui/react';
import { type HTMLChakraProps, type ThemingProps } from '@chakra-ui/system';
import { omitThemingProps, useMultiStyleConfig } from '@chakra-ui/system';
import { Button, Typography } from '@shinetools/sunshine-universal';
import { addYears, format } from 'date-fns';

import IconButton from 'components/_core/IconButton';
import { eeee_PP } from 'helpers/date';

import { type DatePickerProps } from '../..';
import PeriodPickerItem from './components/PeriodPickerItem';
import locales from './locales';
import {
  getHeaderTitle,
  getItemDisabled,
  getItems,
  getItemSelected,
  getTodayLabel,
} from './utils';

export type ViewMode = 'month' | 'year' | 'quarter';

export type PeriodPickerProps = Omit<
  HTMLChakraProps<'div'>,
  'children' | 'onChange' | 'defaultValue'
> &
  ThemingProps<'PeriodPicker'> &
  Pick<DatePickerProps, 'value' | 'onChange' | 'viewMode' | 'modifiers'>;

const MIN_YEAR = 1970;
const MAX_YEAR = 2100;

const PeriodPicker: FC<PeriodPickerProps> = (props) => {
  const styles = useMultiStyleConfig('PeriodPicker', props);
  const {
    modifiers,
    onChange,
    value,
    viewMode = 'month',
    ...rest
  } = omitThemingProps(props);
  const [period, setPeriod] = useState(value ?? new Date());

  const handleNavigation = (direction: -1 | 1) => {
    let result = new Date();
    if (viewMode === 'month') {
      result = addYears(period, 1 * direction);
    } else if (viewMode === 'year') {
      result = addYears(period, 10 * direction);
    } else if (viewMode === 'quarter') {
      result = addYears(period, 1 * direction);
    }
    if (result.getFullYear() < MIN_YEAR) {
      result.setFullYear(MIN_YEAR);
    } else if (result.getFullYear() > MAX_YEAR) {
      result.setFullYear(MAX_YEAR);
    }
    setPeriod(result);
  };

  return (
    <chakra.div __css={styles.root} as={chakra.div} {...rest}>
      <HStack justifyContent="space-between">
        <Typography.Text bold variant="primary">
          {getHeaderTitle(viewMode, period)}
        </Typography.Text>
        <div>
          <IconButton
            aria-label={locales.previous}
            icon="arrow-left"
            iconSize="icon-20"
            isDisabled={period.getFullYear() <= MIN_YEAR}
            onClick={() => handleNavigation(-1)}
            variant="inline-secondary"
          />
          <IconButton
            aria-label={locales.next}
            icon="arrow-right"
            iconSize="icon-20"
            isDisabled={period.getFullYear() >= MAX_YEAR}
            onClick={() => handleNavigation(1)}
            variant="inline-secondary"
          />
        </div>
      </HStack>

      <chakra.div __css={styles.container}>
        {getItems(viewMode, period).map((item) => (
          <PeriodPickerItem
            __css={styles.item}
            date={item}
            isDisabled={getItemDisabled(item, modifiers)}
            isSelected={getItemSelected(item, viewMode, value)}
            key={item.toString()}
            onClick={() => onChange?.(item)}
            viewMode={viewMode}
          >
            {format(item, eeee_PP)}
          </PeriodPickerItem>
        ))}
      </chakra.div>

      <Button
        alignSelf="center"
        onPress={() => {
          const today = new Date();

          setPeriod(today);
          onChange?.(today);
        }}
        // @ts-expect-error Button does not know about the type prop
        type="button"
        variant="discreet"
      >
        {getTodayLabel(viewMode)}
      </Button>
    </chakra.div>
  );
};

export default PeriodPicker;
