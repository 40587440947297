import { enUS, fr } from 'date-fns/locale';
import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    dateFnsLocale: enUS,
    today: 'Today',
  },
  fr: {
    dateFnsLocale: fr,
    today: "Aujourd'hui",
  },
} as const);
