export const root = '/cards';

export const card = `${root}/:creditCardId?/:operationId?`;
export const getCardPath = (creditCardId: string) => `${root}/${creditCardId}`;

export const paymentCard = `${root}/:paymentCardId?/:operationId?`;
export const getPaymentCardPath = (paymentCardId: string) =>
  `${root}/${paymentCardId}`;

export const cardTeamMemberRequestsPath = `${root}/requests/:requestId?`;
export const cardTeamMemberRequests = `${root}/requests`;
export const getCardTeamMemberRequestPath = (requestId: string) =>
  `${cardTeamMemberRequests}/${requestId}`;

export const requestNew = `${cardTeamMemberRequests}/new`;

export const orderNew = `${root}/order-new`;
export const orderNewPhysical = `${orderNew}-physical`;
export const orderNewVirtual = `${orderNew}-virtual`;
export const orderNewVirtualTemporary = `${orderNew}-virtual-temporary`;

export const renewal = `${root}/renewal`;
