import { useIntl } from 'react-intl';
import { forwardRef } from '@chakra-ui/react';

import { Table } from '../../Table';
import { type RowProps } from '../../Table/parts';
import { useFees } from '../context';

export type BalanceLetterConfirmationProps = RowProps;

export const BalanceLetterConfirmation = forwardRef<
  BalanceLetterConfirmationProps,
  'div'
>((props, ref) => {
  const intl = useIntl();
  const { fees, toFixed, toVariable } = useFees();

  const locales = {
    label: intl.formatMessage({
      id: 'subscription.fees_modal.balance_letter_confirmation.label',
    }),
    value: intl.formatMessage(
      {
        id: 'subscription.fees_modal.balance_letter_confirmation.value',
      },
      {
        fixed: toFixed(fees.balanceLetterConfirmation),
        variable: toVariable(fees.balanceLetterConfirmation),
      },
    ),
  };

  return (
    <Table.Row {...props} ref={ref}>
      <Table.CellText>{locales.label}</Table.CellText>
      <Table.CellAmount>{locales.value}</Table.CellAmount>
    </Table.Row>
  );
});
