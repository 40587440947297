/* eslint-disable react/display-name */
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useBreakpointValue } from '@chakra-ui/react';

import {
  type BentoContainer,
  type BentoContainerInput,
} from 'common/bento/types/BentoContainer';
import logger from 'helpers/logger';

import {
  BentoLayoutSize,
  type BentoLayoutVariants,
  LayoutContext,
} from '../../lib/layout';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const asBentoContainer = <TProps extends Record<string, any>>(
  Component: BentoContainerInput<TProps>,
  {
    layouts,
  }: {
    layouts: BentoLayoutVariants;
  },
): BentoContainer<TProps> => {
  return (props) => {
    const history = useHistory();

    const pushBufferHistoryEntry = useCallback(() => {
      const { pathname, search } = history.location;

      history.push(`${pathname}${search}`);
    }, [history]);

    /**
     * This original `history.push` is transparent - it won't re-route the user to anything.
     *
     * It adds an entry to the history, effectively serving as a "buffer",
     * so that any `history.pop` action will NOT eject the user from the SPA, and we can react however we want to it.
     */
    useEffect(() => {
      logger.info('ℹ️ Buffer history entry created.');

      pushBufferHistoryEntry();
    }, [pushBufferHistoryEntry]);

    useEffect(
      () =>
        history.listen((_, action) => {
          if (action === 'POP') {
            /**
             * The history.pop removed our buffer history entry.
             * We re-create it right away.
             */
            pushBufferHistoryEntry();

            logger.info('ℹ️ Buffer history entry recreated.', history.length);
          }
        }),
      [pushBufferHistoryEntry, history],
    );

    const Layout = useBreakpointValue(layouts, 'base') ?? layouts.base;
    const layoutSize =
      useBreakpointValue(BentoLayoutSize, 'base') ?? BentoLayoutSize.base;

    return (
      <LayoutContext.Provider value={{ Layout, layoutSize }}>
        <Component {...props} />
      </LayoutContext.Provider>
    );
  };
};

export default asBentoContainer;
