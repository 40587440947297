import { type FC } from 'react';
import { Flex, type FlexProps } from '@chakra-ui/react';

export type BodyProps = FlexProps;

export const Body: FC<BodyProps> = (props) => (
  <Flex
    alignItems="center"
    backgroundColor="grey.100"
    borderTopRadius="radius-8"
    justifyContent="space-between"
    padding="space-32"
    {...props}
  />
);
