import { type FC, type PropsWithChildren } from 'react';
import { Typography } from '@shinetools/sunshine-universal';

export const AdditionalFees: FC<PropsWithChildren> = (props) => {
  return (
    <Typography.Text className="quota__additional_fees" variant="secondary">
      {props.children}
    </Typography.Text>
  );
};
