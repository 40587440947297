import { type FC, type ReactNode } from 'react';
import { Typography } from '@shinetools/sunshine-universal';

import * as S from './styles';

interface NoAccessScreenProps {
  testId: string;
  locales: {
    disclaimer: string;
    description: string;
  };
  button: ReactNode;
}

const NoAccessScreen: FC<NoAccessScreenProps> = ({
  button,
  locales,
  testId,
}) => (
  <S.FullscreenContainer data-testid={testId}>
    <S.InnerWrapper>
      <S.Logo />
      <Typography.Header>{locales.disclaimer}</Typography.Header>
      <Typography.Text marginBottom="$space.24" marginTop="$space.16">
        {locales.description}
      </Typography.Text>
      {button}
    </S.InnerWrapper>
  </S.FullscreenContainer>
);

export default NoAccessScreen;
