import { forwardRef } from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';

import { Cell, type CellProps } from './Cell';

export type CellAmountProps = CellProps;

export const CellAmount = forwardRef<CellAmountProps, 'div'>((props, ref) => {
  const { __css: css, ...rest } = props;

  return (
    <Cell justifyContent="end" {...rest} ref={ref}>
      <Typography.Text>{rest.children}</Typography.Text>
    </Cell>
  );
});
