import { type FC, useEffect, useRef, useState } from 'react';
import { Box, type StyleProps } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import useLatestRef from 'common/hooks/useLatestRef';

interface YousignData {
  /**
   * Always equal to yousign.
   */
  type: string;
  /**
   * Event type.
   */
  event: string;
  /**
   * The signer ID.
   */
  signer_id: string;
  /**
   * The signature request ID.
   */
  signature_request_id: string;
}

interface YousignAnswer {
  /**
   * Field ID.
   */
  field_id: string;
  /**
   * The field type
   * @see https://developers.yousign.com/docs/signer-fields
   */
  field_type: 'text' | 'checkbox' | 'radio_group';
}

interface YousignDataWithAnswers extends YousignData {
  /**
   * A array of objects who list all the fields on the document.
   */
  answers: YousignAnswer[];
}

interface YousignFrameProps extends StyleProps {
  fullscreen?: boolean;
  signatureLink: string;
  /**
   * A function that is triggered when the signature is opened.
   */
  onStarted?: (data: YousignData & { event: 'started' }) => void;
  /**
   * A function that is triggered when the signature is signed successfully.
   */
  onSuccess?: (data: YousignDataWithAnswers & { event: 'success' }) => void;
  /**
   * A function that is triggered when the signature encountered an error when signing.
   */
  onError?: (data: YousignDataWithAnswers & { event: 'error' }) => void;
  /**
   * A function that is triggered every 5 seconds to inform the signature request is loaded.
   */
  onPing?: (data: YousignData & { event: 'ping' }) => void;
  /**
   * A function that is triggered when the signer declined the signature.
   */
  onDeclined?: (data: YousignData & { event: 'declined' }) => void;
  onLeaveFullscreen?: () => void;
}

const isSandbox = import.meta.env.VITE_API_ENV !== 'production';

// https://developers.yousign.com/docs/iframe-advanced
export const YousignFrame: FC<YousignFrameProps> = ({
  onDeclined = () => {},
  onError = () => {},
  onPing = () => {},
  onStarted = () => {},
  onSuccess = () => {},
  signatureLink,
  ...styleProps
}) => {
  const isMounted = useRef(false);
  const [iframeContainerId] = useState(`yousign-iframe-${uuidv4()}`);

  const onDeclinedRef = useLatestRef(onDeclined);
  const onErrorRef = useLatestRef(onError);
  const onPingRef = useLatestRef(onPing);
  const onStartedRef = useLatestRef(onStarted);
  const onSuccessRef = useLatestRef(onSuccess);

  useEffect(() => {
    isMounted.current = true;
    const init = async () => {
      const { Yousign } = await import('./sdk');

      if (!isMounted.current) {
        return;
      }

      const yousign = new Yousign({
        iframeContainerId,
        isSandbox,
        signatureLink: signatureLink, //To set if you use the sandbox environment
      });

      // Bind all events
      yousign.onDeclined(onDeclinedRef.current);
      yousign.onError(onErrorRef.current);
      yousign.onPing(onPingRef.current);
      yousign.onStarted(onStartedRef.current);
      yousign.onSuccess(onSuccessRef.current);

      setTimeout(() => {
        Object.assign(document.getElementById('yousign-iframe')?.style ?? {}, {
          height: '100%',
          width: '100%',
        });
      }, 1000);
    };
    init();

    return () => {
      isMounted.current = false;
    };
  }, [
    iframeContainerId,
    onDeclinedRef,
    onErrorRef,
    onPingRef,
    onStartedRef,
    onSuccessRef,
    signatureLink,
  ]);

  return (
    <Box id={iframeContainerId} {...styleProps} height="full" width="full" />
  );
};
