import { useCallback } from 'react';

import {
  type CompanyUserFeature,
  type FeatureFilter,
  type FeatureName,
  type Where,
} from '__generated__/GQL';
import useFeaturesContext from 'common/hooks/useFeaturesContext';

interface RequiredFeature {
  name: FeatureName;
  where?: {
    or: Omit<FeatureFilter, '__typename'>[];
  };
  /**
   * Enable access to the feature if a child scope is found
   */
  withChildFeatureOnly?: boolean;
}

const isFeatureWithFilters = <T extends CompanyUserFeature>(
  feature: T,
): feature is T & { where: Where } => {
  return 'where' in feature && feature.where !== null;
};

const getFiltersIntersection = (
  filterA: FeatureFilter | null,
  filterB: FeatureFilter | null,
): string[] => {
  if (filterA === null || filterB === null || filterA.field !== filterB.field) {
    return [];
  }

  const filterAValuesAsStrings = filterA.values.map((s) => s.toString());

  /**
   * Depending of the filter, the key and the type of the values can change (the key could be named roles or users for instance).
   * To avoid checking for each type the name of the key, we can add 'Values' at the end of the key name to find it easily.
   */
  let bValues;
  const keys = Object.keys(filterB);
  for (const key of keys) {
    if (key.endsWith('Values')) {
      bValues = filterB[
        key as keyof FeatureFilter
      ] as unknown as FeatureFilter[];
    }
  }

  const filterBValuesAsStrings = (bValues ?? []).map((s) => s.toString());

  return filterAValuesAsStrings.filter((value) =>
    filterBValuesAsStrings.includes(value),
  );
};

interface FeatureWithFilters {
  name: FeatureName;
  where: Where;
}

interface FeatureWithOnlyOrFilters {
  name: FeatureName;
  where: { or: FeatureFilter[] };
}

const isRequiredFeatureExplicitlyExcludedInGrantedFeatureFilters = ({
  grantedFeature,
  requiredFeature,
}: {
  grantedFeature: FeatureWithFilters;
  requiredFeature: FeatureWithOnlyOrFilters;
}): boolean => {
  // no excluding filters
  if (grantedFeature.where.not === null) {
    return false;
  }

  return requiredFeature.where.or.some((requiredFeatureFilter) =>
    grantedFeature.where.not?.some(
      (grantedFeatureExcludingFilter) =>
        getFiltersIntersection(
          requiredFeatureFilter,
          grantedFeatureExcludingFilter,
        ).length > 0,
    ),
  );
};

const isRequiredFeatureFullyIncludedInGrantedFeatureFilters = ({
  grantedFeature,
  requiredFeature,
}: {
  grantedFeature: FeatureWithOnlyOrFilters;
  requiredFeature: FeatureWithOnlyOrFilters;
}): boolean => {
  // full feature was granted
  if (grantedFeature.where.or === null) {
    return true;
  }

  return requiredFeature.where.or.every((requiredFeatureFilter) =>
    grantedFeature.where.or?.some(
      (grantedFeatureFilter) =>
        getFiltersIntersection(requiredFeatureFilter, grantedFeatureFilter)
          .length === requiredFeatureFilter.values.length,
    ),
  );
};

export function getCanAccessFeature(
  features: CompanyUserFeature[] | undefined,
): (requiredFeature: RequiredFeature) => boolean {
  return (requiredFeature) => {
    if (!features) {
      return false;
    }

    if (requiredFeature.withChildFeatureOnly) {
      const hasChildScope = features.some(({ name }) =>
        name.startsWith(requiredFeature.name),
      );

      if (hasChildScope) {
        return true;
      }
    }

    const grantedFeature = features.find(({ name }) =>
      requiredFeature.name.startsWith(name),
    );

    if (!grantedFeature) {
      return false;
    }

    // the user has the full feature without any filter
    if (!isFeatureWithFilters(grantedFeature)) {
      return true;
    }

    // the user has filters on the feature but the full feature is required
    if (requiredFeature.where === undefined) {
      return false;
    }

    if (
      isRequiredFeatureExplicitlyExcludedInGrantedFeatureFilters({
        grantedFeature,
        requiredFeature: requiredFeature as FeatureWithOnlyOrFilters,
      })
    ) {
      return false;
    }

    return isRequiredFeatureFullyIncludedInGrantedFeatureFilters({
      grantedFeature: grantedFeature as FeatureWithOnlyOrFilters,
      requiredFeature: requiredFeature as FeatureWithOnlyOrFilters,
    });
  };
}

const useCanCompanyUserAccessFeature = () => {
  const { features } = useFeaturesContext();

  /**
   * This function is almost an exact copy of the one in the permissions-library
   * (that we do not want to import in front apps).
   */
  const canAccessFeature = useCallback(
    (requiredFeature: RequiredFeature) =>
      getCanAccessFeature(features)(requiredFeature),
    [features],
  );

  return { canAccessFeature };
};

export default useCanCompanyUserAccessFeature;
