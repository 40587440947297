import { type FC, useCallback, useEffect, useRef, useState } from 'react';
import { addHours } from 'date-fns';

import { clearSentryUser, setSentryUser } from 'common/sentry/userContext';
import webView from 'common/webView';
import { identify, logEvent } from 'features/Analytics/analytics';
import {
  authenticationEmitter,
  AuthenticationEvents,
  decodeToken,
  getUser,
  hasExpiredOrWillSoon,
  logout as authLogout,
  saveUser,
} from 'helpers/auth';
import { type User } from 'helpers/auth/user.schema';
import { useDevice } from 'helpers/device';
import logger from 'helpers/logger';

import Component from './component';
import { type LoginProps, type LoginType } from './types';

type EffectCallback = void | (() => void | undefined);

const MAX_BUILD_LIFETIME_IN_HOURS = 12;
const buildExpirationDate = addHours(new Date(), MAX_BUILD_LIFETIME_IN_HOURS);

const Login: FC<LoginProps> = ({
  children,
  invitationInfo = null,
  invitationSlug = null,
}) => {
  const device = useDevice();
  const isOnSignupPage = window.location.pathname === '/signup';
  const [loading, setLoading] = useState(true);
  const [loginType, setLoginType] = useState<LoginType>(
    isOnSignupPage ? 'sign_up' : 'sign_in',
  );
  const [user, setUser] = useState<User | null>(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // In case of an invitation flow, we should log out only on component mount
  const hasInitiallyLoggedOut = useRef(false);

  const setCurrentUser = (newUser: User): void => {
    if (!newUser?.token) {
      logger.error(
        `No token was received from the auth service for user ${newUser?.uid}`,
        { tags: { feature: 'login', uid: newUser?.uid } },
      );
    }

    setUser(newUser);
    saveUser(newUser);
    setIsAuthenticated(true);
    setSentryUser(newUser.uid);
    setLoading(false);
  };

  /**
   * On web-app first mount, re-authenticate the user if we find valid credentials in localStorage.
   */
  useEffect((): void => {
    try {
      const userInfo = getUser();

      if (userInfo) {
        const decoded = decodeToken(userInfo.token);
        const isValid = Boolean(decoded && !hasExpiredOrWillSoon(decoded));

        /**
         * We set the user in any case...
         */
        setUser(userInfo);

        /**
         * But only mark him as authenticated if
         * - his credentials are valid, so that he can refresh his authentication if they are not.
         * - or if he is in the webView, in which case we assert credentials are fresh
         * (avoids infinitely redirecting users with invalid local clocktime from RN <-> web)
         */
        if (isValid || webView.isInWebView) {
          setIsAuthenticated(true);
          setSentryUser(userInfo.uid);
          identify(userInfo.uid);
        }
      }

      setLoading(false);
    } catch (e) {
      logger.error(e, { tags: { feature: 'login' } });
      setLoading(false);
    }
  }, []);

  const logout = useCallback(
    (options?: { shouldHardReload?: boolean }): void => {
      logEvent({ name: 'Logout' });
      authLogout({
        deviceToken: device.token,
        phone: user?.phone,
        shouldHardReload: options?.shouldHardReload,
      });
      setUser(null);
      clearSentryUser();
    },
    [device.token, user?.phone, setUser],
  );

  useEffect((): EffectCallback => {
    const onSoftLogout = () => logout({ shouldHardReload: false });
    const onUnauthorized = (): void => {
      webView.postMessage({ action: 'AUTHENTICATION_EXPIRED' });
      logEvent({ name: 'Unauthorized' });
      if (buildExpirationDate < new Date()) {
        window.location.reload();
      } else {
        setIsAuthenticated(false);
      }
    };
    authenticationEmitter.addListener(AuthenticationEvents.OnLogout, logout);
    authenticationEmitter.addListener(
      AuthenticationEvents.OnSoftLogout,
      onSoftLogout,
    );
    authenticationEmitter.addListener(
      AuthenticationEvents.OnUnauthorized,
      onUnauthorized,
    );
    return (): void => {
      authenticationEmitter.removeListener(
        AuthenticationEvents.OnLogout,
        logout,
      );
      authenticationEmitter.removeListener(
        AuthenticationEvents.OnSoftLogout,
        onSoftLogout,
      );
      authenticationEmitter.removeListener(
        AuthenticationEvents.OnUnauthorized,
        onUnauthorized,
      );
    };
  }, [user, device, logout]);

  useEffect(() => {
    if (invitationSlug && !hasInitiallyLoggedOut.current) {
      logout({ shouldHardReload: false });
      hasInitiallyLoggedOut.current = true;
    }
  }, [invitationSlug, logout]);

  useEffect(() => {
    // Subscribe to local storage events to detect logout from another tab
    const listener = (event: StorageEvent) => {
      if (event.storageArea !== localStorage) {
        return;
      }
      if (event.key === 'user') {
        if (!event.newValue) {
          window.location.href = '/';
        }
      }
    };
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, []);

  return (
    <Component
      invitationInfo={invitationInfo}
      invitationSlug={invitationSlug}
      isAuthenticated={isAuthenticated}
      loading={loading}
      loginType={loginType}
      setCurrentUser={setCurrentUser}
      setLoginType={setLoginType}
      user={user}
    >
      {children}
    </Component>
  );
};

export default Login;
