import { type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { gql } from '@apollo/client';
import { Flex, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Icon, Typography } from '@shinetools/sunshine-universal';
import { z } from 'zod';

import { usePublicQuery } from 'common/graphql/usePublicQuery';
import SunshineCard from 'components/_core/SunshineCard';
import Header from 'features/Onboarding/components/Header';
import OBStickyButton from 'features/Onboarding/components/OBStickyButton/OBStickyButton';

import locales from './locales';

const eligibilitySchema = z.object({
  hasEUCitizenshipOrVisa: z.literal<boolean>(true),
  isAdult: z.literal<boolean>(true),
});

interface SasuCreationEligibilityFormProps {
  onSubmit: () => void;
}

/**
 * A form asking the user if they're eligible to create a SASU
 */
const SasuCreationEligibilityForm: FC<SasuCreationEligibilityFormProps> = ({
  onSubmit,
}) => {
  const { data } = usePublicQuery<{
    isSasuFormalitiesFree: boolean;
  }>({
    query: gql`
      query GetIsSasuFormalitiesFree {
        isSasuFormalitiesFree
      }
    `,
  });

  const form = useForm({
    defaultValues: {
      hasEUCitizenshipOrVisa: undefined,
      isAdult: undefined,
    },
    mode: 'onChange',
    resolver: zodResolver(eligibilitySchema),
  });

  return (
    <Flex direction="column" height={{ base: 'full', md: 'auto' }}>
      <Header title={locales.title} />

      <Flex
        as="form"
        direction="column"
        height="full"
        justify="space-between"
        onSubmit={form.handleSubmit(() => {
          onSubmit();
        })}
      >
        <SunshineCard.Group>
          <Controller
            control={form.control}
            name="isAdult"
            render={({ field }) => (
              <SunshineCard
                isChecked={field.value}
                onChange={field.onChange}
                title={locales.labels.isAdult}
                variant="checkbox"
              />
            )}
          />

          <Controller
            control={form.control}
            name="hasEUCitizenshipOrVisa"
            render={({ field }) => (
              <SunshineCard
                isChecked={field.value}
                onChange={field.onChange}
                title={locales.labels.hasEUCitizenshipOrVisa}
                variant="checkbox"
              />
            )}
          />
        </SunshineCard.Group>

        {data?.isSasuFormalitiesFree ? (
          <SunshineCard backgroundColor="grey.100" marginTop="space-24">
            <SunshineCard.Title>
              <FormattedMessage id="onboarding.sasu_creation.eligibility.offer_banner.title" />
            </SunshineCard.Title>
            <SunshineCard.Content>
              <SunshineCard.Text>
                <FormattedMessage id="onboarding.sasu_creation.eligibility.offer_banner.description" />
              </SunshineCard.Text>
            </SunshineCard.Content>
            <SunshineCard.Slot name="action">
              <Tag
                backgroundColor="orange.600"
                color="white"
                height="32px"
                paddingX="space-12"
                size="sm"
              >
                <TagLeftIcon boxSize="14px" marginRight="space-4">
                  <Icon icon="gift" />
                </TagLeftIcon>
                <TagLabel>
                  <FormattedMessage id="onboarding.sasu_creation.eligibility.offer_banner.tag_text" />
                </TagLabel>
              </Tag>
            </SunshineCard.Slot>
          </SunshineCard>
        ) : null}

        <OBStickyButton isDisabled={!form.formState.isValid} type="submit">
          {locales.cta}
        </OBStickyButton>

        {data?.isSasuFormalitiesFree ? (
          <Typography.Text marginTop="$space.8" size="tiny" variant="discreet">
            <FormattedMessage id="onboarding.sasu_creation.eligibility.offer.conditions" />
          </Typography.Text>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default SasuCreationEligibilityForm;
