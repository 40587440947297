import { capitalize } from 'helpers/string';

import type * as GQL from '../overview.gql';

type ActiveInsurance = {
  isActive: true;
};

type DisabledInsurance = {
  isActive: false;
  plan: string;
};

export const getInsuranceCoverage = (
  data?: GQL.SubscriptionManagementOverviewQuery,
): ActiveInsurance | DisabledInsurance => {
  const subscription = data?.viewer.company.subscriptionPlan;
  const currentPlan = data?.viewer.company.currentPlan;

  if (!subscription || !subscription.isLateOnPayment || !currentPlan) {
    return {
      isActive: true,
    };
  }

  return {
    isActive: false,
    plan: capitalize(currentPlan.providerPlanId),
  };
};
