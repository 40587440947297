import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, type BoxProps, Flex } from '@chakra-ui/react';
import { Callout, Typography } from '@shinetools/sunshine-universal';

import { getInsuranceCoverage } from '../../helpers/getInsuranceCoverage';
import type * as GQLOverview from '../../overview.gql';

export type OverviewInsuranceCoverageProps = BoxProps & {
  data?: GQLOverview.SubscriptionManagementOverviewQuery;
};

export const OverviewInsuranceCoverage: FC<OverviewInsuranceCoverageProps> = (
  props,
) => {
  const { data, ...rest } = props;

  const insurance = getInsuranceCoverage(data);

  if (insurance.isActive) {
    return null;
  }

  return (
    <Box {...rest}>
      <Callout variant="warning" withIcon={false}>
        <Flex alignItems="flex-start" direction="column" gap="space-8">
          <Typography.Text size="small" variant="secondary">
            <FormattedMessage
              id="subscription.overview.insurance_coverage.description"
              values={{
                currentPlan: insurance.plan,
              }}
            />
          </Typography.Text>
        </Flex>
      </Callout>
    </Box>
  );
};
