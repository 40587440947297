import { useEffect, useState } from 'react';
import { getRemoteConfig } from 'firebase/remote-config';

import { logEvent } from 'features/Analytics/analytics';

import {
  fetchAndActivateRemoteConfig,
  getConfigGettersOnceLoaded,
} from '../../../../common/remoteConfig';

const enableFraudWarningFirebaseKey = 'enableFraudWarningModal';
const ONE_MINUTE = 60 * 1000;
const CACHE_INVALIDATION_FETCH_INTERVAL = 1;
const ONE_DAY = 24 * 60 * ONE_MINUTE;
const ONE_WEEK = 7 * ONE_DAY;
const DELAY_BETWEEN_TWO_FRAUD_WARNINGS = 2 * ONE_WEEK;
const HAS_SEEN_FRAUD_WARNING_MODAL_AT_LOCAL_STORAGE_KEY =
  'hasSeenFraudWarningModalAt';

const useFraudWarningModal = () => {
  const [shouldSeeFraudWarningModal, setShouldSeeFraudWarningModal] =
    useState<boolean>(false);

  const [isFraudWarningModalEnabled, setIsFraudWarningModalEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const { getBoolean } = await getConfigGettersOnceLoaded();

      const config = getRemoteConfig();

      config.settings = {
        fetchTimeoutMillis: ONE_MINUTE,
        minimumFetchIntervalMillis: CACHE_INVALIDATION_FETCH_INTERVAL,
      };

      // Invalidate remote config cache
      await fetchAndActivateRemoteConfig(config);

      const result = getBoolean(enableFraudWarningFirebaseKey);
      setIsFraudWarningModalEnabled(result);
    })();
  }, []);

  useEffect(() => {
    const checkModalSeenStatus = async () => {
      const lastModalDisplayTimestamp = parseInt(
        window.localStorage.getItem(
          HAS_SEEN_FRAUD_WARNING_MODAL_AT_LOCAL_STORAGE_KEY,
        ) ?? '0',
      );
      const nowTimestamp = new Date().getTime();

      setShouldSeeFraudWarningModal(
        nowTimestamp - lastModalDisplayTimestamp >
          DELAY_BETWEEN_TWO_FRAUD_WARNINGS,
      );
    };
    checkModalSeenStatus();
  }, []);

  const dismissFraudWarning = () => {
    setShouldSeeFraudWarningModal(false);
    window.localStorage.setItem(
      HAS_SEEN_FRAUD_WARNING_MODAL_AT_LOCAL_STORAGE_KEY,
      new Date().getTime().toString(),
    );
    logEvent({ name: 'Has_Seen_Fraud_Warning_Modal' });
  };

  // Should also only display if account is validated (this is ensured at the upper level to mutualize data loading)
  const shouldDisplayFraudWarning =
    shouldSeeFraudWarningModal && isFraudWarningModalEnabled;

  return {
    dismissFraudWarning,
    shouldDisplayFraudWarning,
  };
};

export default useFraudWarningModal;
