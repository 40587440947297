import { capitalize } from 'helpers/string';

import type * as GQL from '../overview.gql';

type NoScheduledDowngrade = {
  hasScheduledDowngrade: false;
};

type ScheduledDowngrade = {
  hasScheduledDowngrade: true;
  nextStartingAt: Date;
  nextPlanName: string;
  currentPlanName: string;
};

export const getScheduledDowngrade = (
  data?: GQL.SubscriptionManagementOverviewQuery,
): NoScheduledDowngrade | ScheduledDowngrade => {
  const subscription = data?.viewer.company.subscriptionPlan;
  const currentPlan = data?.viewer.company.currentPlan;

  if (
    !subscription ||
    !subscription.scheduledDowngrade ||
    !subscription.nextBillingAt ||
    !currentPlan
  ) {
    return {
      hasScheduledDowngrade: false,
    };
  }

  return {
    currentPlanName: capitalize(currentPlan?.providerPlanId),
    hasScheduledDowngrade: true,
    nextPlanName: capitalize(subscription.scheduledDowngrade),
    nextStartingAt: new Date(subscription.nextBillingAt),
  };
};
