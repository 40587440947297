import * as Types from '../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TeamOnboardingTutorialsCardQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ViewerCardsFilters>;
}>;


export type TeamOnboardingTutorialsCardQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', uid: string, cards: { __typename: 'ViewerCardsConnection', edges: Array<{ __typename: 'CardEdge', node: { __typename: 'Card', cardId: string } }> } } };


export const TeamOnboardingTutorialsCardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"teamOnboardingTutorialsCard"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ViewerCardsFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"cards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"StringValue","value":"postOnboardingFeaturesTutorial","block":false}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cardId"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<TeamOnboardingTutorialsCardQuery, TeamOnboardingTutorialsCardQueryVariables>;