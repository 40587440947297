import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { forwardRef } from '@chakra-ui/react';

import Button, { type ButtonProps } from 'components/_core/Button';

import * as routes from '../../../../../../../routes';

export type IncreaseLimitButtonProps = Omit<ButtonProps, 'children'>;

export const IncreaseLimitButton = forwardRef<
  IncreaseLimitButtonProps,
  'button'
>((props, ref) => {
  return (
    <Button
      as={Link}
      to={routes.plans}
      variant="inline-primary"
      {...props}
      ref={ref}
    >
      <FormattedMessage id="subscription.overview.quotas.increase_limit" />
    </Button>
  );
});
