import { Box, type BoxProps, Collapse, forwardRef } from '@chakra-ui/react';

import { useQuota } from '../context';

export type FooterProps = BoxProps;

export const Footer = forwardRef<FooterProps, 'div'>((props, ref) => {
  const { __css: css, ...rest } = props;
  const { isExpandable, isOpen, theme } = useQuota();

  return (
    <Collapse className="quota__footer" in={isExpandable && isOpen}>
      <Box __css={{ ...theme.footer, ...css }} {...rest} ref={ref}>
        {props.children}
      </Box>
    </Collapse>
  );
});
