/* eslint-disable react/display-name */
import { Children, forwardRef } from 'react';
import {
  Divider,
  List,
  ListItem,
  type ListItemProps,
  type ListProps,
} from '@chakra-ui/react';

interface DropdownOptionProps extends ListItemProps {
  isHighlighted?: boolean;
}

const DropdownOption = forwardRef<HTMLLIElement, DropdownOptionProps>(
  ({ children, isHighlighted = false, onClick, ...props }, ref) => {
    return (
      <ListItem
        backgroundColor={
          isHighlighted ? 'input-backgroundColor-hover' : 'inherit'
        }
        cursor={onClick ? 'pointer' : 'initial'}
        onClick={onClick}
        paddingX="space-12"
        paddingY="space-8"
        ref={ref}
        {...props}
      >
        {children}
      </ListItem>
    );
  },
);

const Dropdown = forwardRef<HTMLUListElement, ListProps>(
  ({ children, ...props }, ref) => {
    const arrayChildren = Children.toArray(children);

    return (
      <List
        backgroundColor="white"
        border="1px solid"
        borderColor="border"
        borderRadius="radius-4"
        marginTop="space-4"
        paddingY="space-8"
        position="absolute"
        ref={ref}
        width="full"
        zIndex="dropdown"
        {...props}
      >
        {Children.map(arrayChildren, (option, index) => {
          const isLast = index === arrayChildren.length - 1;

          return (
            <>
              {option}
              {isLast ? null : <Divider marginX="space-12" width="auto" />}
            </>
          );
        })}
      </List>
    );
  },
);

type DropdownType = typeof Dropdown & { Option: typeof DropdownOption };

(Dropdown as DropdownType).Option = DropdownOption;

export default Dropdown as DropdownType;
