import { useEffect } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Image, type UseDisclosureReturn, VStack } from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';

import { type ProviderPlanId } from '__generated__/GQL';
import { preloadImage } from 'common/preloadImage';
import Button from 'components/_core/Button';
import Modal from 'components/_core/Modal';
import Loader from 'components/Loader';
import { orderNew as orderNewCardRoute } from 'features/PaymentCards/routes';

import { type PlanChangeSuccessDialogFragment } from '../../overview.gql';
import withNewCard from './assets/withNewCard.png';
import withoutNewCard from './assets/withoutNewCard.png';
import { getPlanChangeData } from './getPlanChangeData';

export const PlanChangeSuccessDialog = ({
  planChangeQuery,
  switchedFromPlan,
  ...props
}: UseDisclosureReturn & {
  switchedFromPlan: ProviderPlanId | null;
  planChangeQuery: PlanChangeSuccessDialogFragment;
}) => {
  const planChange = switchedFromPlan
    ? getPlanChangeData(switchedFromPlan, planChangeQuery)
    : null;

  /**
   * Preload the images before the modal is opened.
   */
  useEffect(() => {
    if (switchedFromPlan && planChange) {
      preloadImage(
        planChange.hasUnlockedNewPhysicalCards ? withNewCard : withoutNewCard,
      );
    }
  }, [planChange, switchedFromPlan]);

  if (!switchedFromPlan) {
    return (
      <Modal {...props}>
        <Modal.Header />

        <Modal.Body>
          <Loader />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal {...props}>
      <Modal.Header />

      {planChange ? (
        <>
          <Modal.Body>
            <VStack align="stretch" spacing="space-32">
              <VStack align="stretch" spacing="space-16">
                <Typography.Header size="large">
                  <FormattedMessage
                    id="subscription.plan_change_success_dialog.title"
                    values={{ planName: planChange.nextPlan.brandName }}
                  />
                </Typography.Header>

                {(() => {
                  if (planChange.isDowngrade) {
                    return (
                      <Typography.Text variant="secondary">
                        <FormattedMessage
                          id="subscription.plan_change_success_dialog.downgrade_description"
                          values={{
                            newPlanName: planChange.nextPlan.brandName,
                            newPlanStartDate: (
                              <FormattedDate
                                day="numeric"
                                month="short"
                                value={
                                  planChangeQuery.subscriptionPlan!
                                    .nextBillingAt!
                                }
                                year="numeric"
                              />
                            ),
                            previousPlanName: planChange.previousPlan.brandName,
                          }}
                        />
                      </Typography.Text>
                    );
                  }

                  if (planChange.hasUnlockedNewPhysicalCards) {
                    return (
                      <Typography.Text variant="secondary">
                        <FormattedMessage
                          id="subscription.plan_change_success_dialog.prompt_order_card"
                          values={{
                            newPlanName: planChange.nextPlan.brandName,
                          }}
                        />
                      </Typography.Text>
                    );
                  }

                  return (
                    <Typography.Text variant="secondary">
                      <FormattedMessage id="subscription.plan_change_success_dialog.regular_upgrade_description" />
                    </Typography.Text>
                  );
                })()}
              </VStack>

              <Image
                src={
                  planChange.hasUnlockedNewPhysicalCards
                    ? withNewCard
                    : withoutNewCard
                }
              />
            </VStack>
          </Modal.Body>

          <Modal.Footer justifyContent="flex-end">
            {planChange.hasUnlockedNewPhysicalCards ? (
              <Button as={Link} icon="arrow-right" to={orderNewCardRoute}>
                <FormattedMessage id="subscription.plan_change_success_dialog.continue_and_order_card_cta" />
              </Button>
            ) : (
              <Button onClick={props.onClose}>
                <FormattedMessage id="subscription.plan_change_success_dialog.continue_cta" />
              </Button>
            )}
          </Modal.Footer>
        </>
      ) : (
        <Modal.Body>
          <Loader />
        </Modal.Body>
      )}
    </Modal>
  );
};
