import { endOfQuarter, format, startOfQuarter } from 'date-fns';

import { LLL } from 'helpers/date';

import { type DatePickerViewMode } from '../../..';

export const getItemText = (mode: DatePickerViewMode, item: Date): string => {
  switch (mode) {
    case 'year': {
      return item.getFullYear().toString();
    }
    case 'quarter': {
      const [start, end] = [startOfQuarter(item), endOfQuarter(item)];
      return `${format(start, LLL)} - ${format(end, LLL)}`;
    }
    case 'month': {
      return format(item, LLL);
    }
    default:
      throw new Error('Invalid mode');
  }
};
