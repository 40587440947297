import * as Types from '../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreatePowerOfAttorneyNotaryFourezSignatureLinkMutationVariables = Types.Exact<{
  input: Types.CreatePowerOfAttorneyNotaryFourezSignatureLinkInput;
}>;


export type CreatePowerOfAttorneyNotaryFourezSignatureLinkMutation = { __typename: 'Mutation', createPowerOfAttorneyNotaryFourezSignatureLink: { __typename: 'CreatePowerOfAttorneyNotaryFourezSignatureLinkPayload', signatureLink: string } };


export const CreatePowerOfAttorneyNotaryFourezSignatureLinkDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createPowerOfAttorneyNotaryFourezSignatureLink"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePowerOfAttorneyNotaryFourezSignatureLinkInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPowerOfAttorneyNotaryFourezSignatureLink"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signatureLink"}}]}}]}}]} as unknown as DocumentNode<CreatePowerOfAttorneyNotaryFourezSignatureLinkMutation, CreatePowerOfAttorneyNotaryFourezSignatureLinkMutationVariables>;