import { useIntl } from 'react-intl';
import { forwardRef } from '@chakra-ui/react';

import { PaymentCardType } from '__generated__/GQL';

import { Table } from '../../Table';
import { type RowProps } from '../../Table/parts';
import { useFees } from '../context';

export type PaymentCardProps = RowProps;

export const PaymentCard = forwardRef<PaymentCardProps, 'div'>((props, ref) => {
  const intl = useIntl();
  const {
    plan: {
      paymentCard: { type },
    },
  } = useFees();

  const locales = {
    label:
      type === PaymentCardType.BasicDebitCard
        ? intl.formatMessage({
            id: 'subscription.fees_modal.payment_card.label_basic',
          })
        : intl.formatMessage({
            id: 'subscription.fees_modal.payment_card.label_premium',
          }),
    value: intl.formatMessage({
      id: 'subscription.fees_modal.payment_card.value',
    }),
  };

  return (
    <Table.Row {...props} ref={ref}>
      <Table.CellText>{locales.label}</Table.CellText>
      <Table.CellAmount>{locales.value}</Table.CellAmount>
    </Table.Row>
  );
});
