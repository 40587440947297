import 'react-day-picker/dist/style.css';

import { type CSSProperties, type FC } from 'react';
import {
  DayPicker,
  type DayPickerProps,
  type PropsSingle,
} from 'react-day-picker';
import { Box } from '@chakra-ui/react';
import { Button, Icon } from '@shinetools/sunshine-universal';

import IconButton, { type IconButtonProps } from '../IconButton';
import PeriodPicker from './components/PeriodPicker';
import locales from './locales';

export type DatePickerViewMode = 'day' | 'month' | 'year' | 'quarter';

export type DatePickerProps = {
  onChange: (date: Date | undefined) => void;
  value: PropsSingle['selected'];
  modifiers?: DayPickerProps['modifiers'];
  fixedWeeks?: DayPickerProps['fixedWeeks'];
  viewMode?: DatePickerViewMode;
  withMonthAndYearPickers?: boolean;
} & (
  | {
      withSetTodayButton?: never;
      initialFocus?: never;
    }
  | {
      withSetTodayButton?: boolean;
      initialFocus?: DayPickerProps['autoFocus'];
    }
);

const WIDTH = '272px';

const cellStyle: CSSProperties = {
  fontFamily: 'body',
  fontSize: 'font-13',
  width: 'space-32',
};

const DatePicker: FC<DatePickerProps> = ({
  modifiers,
  onChange,
  value,
  viewMode = 'day',
  withMonthAndYearPickers = false,
  withSetTodayButton = true,
  ...props
}) => {
  if (viewMode && viewMode !== 'day') {
    return (
      <PeriodPicker
        maxWidth={WIDTH}
        minWidth={WIDTH}
        modifiers={modifiers}
        onChange={onChange}
        padding="space-16"
        value={value}
        viewMode={viewMode}
      />
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="space-16"
      padding="space-16"
      sx={{
        // day cell
        '.rdp-day': {
          height: 'auto',
          padding: 0,
        },

        '.rdp-day.rdp-disabled': {
          opacity: 1,
        },

        // disabled day
        '.rdp-day.rdp-disabled .rdp-day_button': {
          color: 'grey.500',
        },

        // day button selected
        '.rdp-day.rdp-selected .rdp-day_button': {
          backgroundColor: 'primary',
          color: 'white',
          fontWeight: 'weight-700',
        },

        // current day
        '.rdp-day.rdp-today': {
          fontWeight: 'weight-500',
        },

        // day button hover state
        '.rdp-day:not(.rdp-selected) .rdp-day_button:hover:not([disabled])': {
          backgroundColor: 'grey.300',
        },

        // day button
        '.rdp-day_button': {
          border: 'none',
          borderRadius: '4px',
          color: 'primary',
          height: 'space-24',
          lineHeight: '20px',
          transition: 'all 0.2s ease-out',
          width: 'inherit',
        },

        // day button focused
        '.rdp-day_button:focus-visible:not([disabled])': {
          boxShadow: 'outline-rebranded',
          outline: 'none',
        },

        // month container with caption and days grid
        '.rdp-month': {
          display: 'flex',
          flexDirection: 'column',
          gap: 'space-16',
        },

        // month caption in the header
        '.rdp-month_caption': {
          alignItems: 'center',
          color: 'primary',
          fontFamily: 'body',
          fontSize: 'font-15',
          fontWeight: 'weight-500',
          // Set same height as navigation icon to align vertically.
          // Can't use flexbox because the nav buttons have position absolute.
          height: '30px',
          paddingLeft: 'space-2',
        },

        // table
        '.rdp-month_grid': {
          borderCollapse: 'separate',
          borderSpacing: 'space-2',
        },

        // reset default box size so it takes the size of the content
        '.rdp-nav': {
          height: 'inherit',
        },

        // heading cells (days of the week)
        '.rdp-weekday': {
          color: 'secondary',
          fontFamily: 'body',
          fontWeight: 'weight-500',
          height: 'auto',
          paddingBottom: 'space-16',
          paddingTop: 'space-16',
        },

        '.rdp-weekday, .rdp-day': {
          ...cellStyle,
        },
      }}
      width={WIDTH}
    >
      <DayPicker
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={props.initialFocus}
        {...(withMonthAndYearPickers
          ? { captionLayout: 'dropdown', hideNavigation: true }
          : {})}
        components={{
          Chevron: ({ disabled, orientation }) => {
            switch (orientation) {
              case 'up':
                return <Icon disabled={disabled} icon="chevron-up" />;
              case 'down':
              default:
                return <Icon disabled={disabled} icon="chevron-down" />;
            }
          },
          NextMonthButton: ({
            children,
            className,
            disabled,
            onClick,
            ...rest
          }) => (
            <IconButton
              {...(rest as IconButtonProps)}
              icon="arrow-right"
              iconSize="icon-20"
              isDisabled={disabled}
              onClick={onClick}
              variant="inline-secondary"
            />
          ),
          PreviousMonthButton: ({
            children,
            className,
            disabled,
            onClick,
            ...rest
          }) => (
            <IconButton
              {...(rest as IconButtonProps)}
              icon="arrow-left"
              iconSize="icon-20"
              isDisabled={disabled}
              onClick={onClick}
              variant="inline-secondary"
            />
          ),
        }}
        locale={locales.dateFnsLocale}
        mode="single"
        modifiers={modifiers}
        onSelect={onChange}
        selected={value}
        {...props}
      />
      {withSetTodayButton ? (
        <Button
          alignSelf="center"
          onPress={() => onChange?.(new Date())}
          // @ts-expect-error Button does not know about the type prop
          type="button"
          variant="discreet"
        >
          {locales.today}
        </Button>
      ) : null}
    </Box>
  );
};

export default DatePicker;
