import * as Types from '../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CancelScheduledDowngradeMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.CancelDowngradeInput>;
}>;


export type CancelScheduledDowngradeMutation = { __typename: 'Mutation', cancelDowngrade: { __typename: 'UpgradeDowngradePayload', subscriptionPlan: { __typename: 'SubscriptionPlan', subscriptionId: string, providerPlanId: string } | null } };


export const CancelScheduledDowngradeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"cancelScheduledDowngrade"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CancelDowngradeInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelDowngrade"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subscriptionPlan"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subscriptionId"}},{"kind":"Field","name":{"kind":"Name","value":"providerPlanId"}}]}}]}}]}}]} as unknown as DocumentNode<CancelScheduledDowngradeMutation, CancelScheduledDowngradeMutationVariables>;