import {
  FormattedDate,
  FormattedDateParts,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl';
import {
  forwardRef,
  HStack,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Icon, Typography } from '@shinetools/sunshine-universal';

import { type SubscriptionInvoice } from '__generated__/GQL';
import Invoice from 'assets/brand/invoice.png';
import Button from 'components/_core/Button';
import IconButton from 'components/_core/IconButton';

import { Table, type TableProps } from '../Table';
import { getInvoiceCategoryMetadata } from './helpers/getInvoiceCategoryMetadata';
import { getInvoiceDate } from './helpers/getInvoiceDate';

type SubscriptionInvoiceNode = Pick<
  SubscriptionInvoice,
  | 'invoiceAt'
  | 'subscriptionInvoiceId'
  | 'category'
  | 'paidAt'
  | 'totalAmountWithoutVAT'
  | 'url'
>;
type SubscriptionInvoiceEdge = { node: SubscriptionInvoiceNode }[];

export type InvoicesTableProps = TableProps & {
  invoices?: SubscriptionInvoiceEdge;
  loading?: boolean;
  loadingMore?: boolean;
};

export const InvoicesTable = forwardRef<InvoicesTableProps, 'div'>(
  (props, ref) => {
    const intl = useIntl();
    const { children, invoices, loading, loadingMore, ...rest } = props;

    const isSmall = useBreakpointValue({
      base: true,
      lg: false,
    });
    const isEmpty = !loading && !loadingMore && invoices?.length === 0;
    const downloadLocale = intl.formatMessage({
      id: 'subscription.invoices.table.cell.download',
    });

    return (
      <Table
        columnGap="space-16"
        gridTemplateColumns={`minmax(220px, 1fr) repeat(2, 1fr) ${isSmall ? 'minmax(50px, auto)' : 'minmax(180px, auto)'}`}
        {...rest}
        ref={ref}
      >
        <Table.Header>
          <Table.Column>
            <FormattedMessage id="subscription.invoices.table.header.date" />
          </Table.Column>

          <Table.Column>
            <FormattedMessage id="subscription.invoices.table.header.type" />
          </Table.Column>

          <Table.Column justifySelf="flex-end">
            <FormattedMessage id="subscription.invoices.table.header.amount" />
          </Table.Column>

          <Table.Column />
        </Table.Header>
        <Table.Body>
          {isEmpty ? (
            <Table.Row
              alignItems="center"
              display="flex"
              flexDirection="column"
              gap="space-8"
              justifyContent="center"
              width="full"
            >
              <Image src={Invoice} width="space-80" />

              <Typography.Text variant="secondary">
                <FormattedMessage id="subscription.invoices.table.emptyState" />
              </Typography.Text>
            </Table.Row>
          ) : null}

          <Table.LoadingRow
            in={loading}
            noOfColumns={4}
            noOfLinesTemplate={[2]}
            noOfRows={3}
          />

          {invoices?.map(({ node: invoice }) => {
            const invoiceCategoryMetadata = getInvoiceCategoryMetadata(invoice);

            return (
              <Table.Row key={invoice.subscriptionInvoiceId}>
                <Table.Cell
                  alignItems="flex-start"
                  flexDirection="column"
                  gap="space-4"
                  justifyContent="center"
                >
                  <Typography.Text bold>
                    <FormattedDateParts
                      month="long"
                      value={new Date(invoice.invoiceAt)}
                      year="numeric"
                    >
                      {(parts) => {
                        const date = getInvoiceDate(parts);
                        return date ? (
                          <>
                            {date.month} {date.year}
                          </>
                        ) : null;
                      }}
                    </FormattedDateParts>
                  </Typography.Text>

                  {invoice.paidAt ? (
                    <Typography.Text size="small" variant="secondary">
                      <FormattedMessage
                        id="subscription.invoices.table.cell.date"
                        values={{
                          date: (
                            <FormattedDate
                              day="2-digit"
                              month="long"
                              value={new Date(invoice.paidAt)}
                              year="numeric"
                            />
                          ),
                        }}
                      />
                    </Typography.Text>
                  ) : null}
                </Table.Cell>

                <Table.Cell>
                  <HStack alignItems="center" spacing="space-12">
                    <Icon icon={invoiceCategoryMetadata.icon} size="regular" />

                    <Typography.Text variant="secondary">
                      <FormattedMessage {...invoiceCategoryMetadata.label} />
                    </Typography.Text>
                  </HStack>
                </Table.Cell>

                <Table.Cell justifyContent="flex-end">
                  <Typography.Text bold>
                    <FormattedMessage
                      id="subscription.invoices.table.cell.amount"
                      values={{
                        amount: (
                          <FormattedNumber
                            currency="EUR"
                            style="currency"
                            value={invoice.totalAmountWithoutVAT / 100}
                          />
                        ),
                      }}
                    />
                  </Typography.Text>
                </Table.Cell>

                <Table.Cell justifyContent="flex-end">
                  {isSmall ? (
                    <IconButton
                      aria-label={downloadLocale}
                      as="a"
                      download
                      href={invoice.url}
                      icon="download"
                      variant="secondary"
                    />
                  ) : (
                    <Button
                      as="a"
                      download
                      href={invoice.url}
                      icon="download"
                      variant="secondary"
                    >
                      {downloadLocale}
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.LoadingRow
            in={loadingMore}
            noOfColumns={4}
            noOfLinesTemplate={[2]}
            noOfRows={3}
          />
        </Table.Body>
        {children}
      </Table>
    );
  },
);
