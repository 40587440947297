import { type MutableRefObject, useLayoutEffect, useRef } from 'react';

// https://epicreact.dev/the-latest-ref-pattern-in-react/
const useLatestRef = <T>(value: T): MutableRefObject<T> => {
  const valueRef = useRef<T>(value);

  useLayoutEffect(() => {
    valueRef.current = value;
  });

  return valueRef;
};

export default useLatestRef;
