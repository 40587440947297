import cc from 'currency-codes';
import Dinero from 'dinero.js';

import { type DetailedTransactionFragment } from 'features/Bank/pages/Overview/Sidebar/graphql/fragment/detailedTransaction.gql';

import LOCALE, { type SupportedLanguages } from './locale';

// TODO floating point arithmetic shouldn't be necessary in order to avoid floating point errors
interface Amount {
  currency?: DetailedTransactionFragment['currency'];
  type?: DetailedTransactionFragment['type'];
  value: DetailedTransactionFragment['value'];
  currencyDisplay?: 'symbol' | 'code' | 'name';
}

const STARTS_WITH_DIGIT_REGEX = /^\d/;

// Shamelessly copied from https://github.com/shinetools/bank-accounts-service/blob/master/api/helpers/addFloatValues.ts#L6-L16
const toMajorCurrencyUnit: (params: {
  amount: number;
  currency: Dinero.Currency;
}) => number = ({ amount, currency = 'EUR' }) =>
  Dinero({
    amount,
    currency,
    precision: cc.code(currency)?.digits,
  }).toUnit();

export const formatAmount = ({
  // Set isMinorUnitValue to true if the provided amount is in minor units (e.g., cents) and needs conversion to major units (e.g., euros).
  isMinorUnitValue = false,
  forcePlusSign = true,
  // withNoMinorUnitDigits will remove the 'cents' from the output
  withNoMinorUnitDigits = false,
  value,
  currency = 'EUR',
  type = Math.sign(value) === 1 ? 'payin' : 'payout',
  lang = LOCALE,
  currencyDisplay = 'symbol',
  isExchangeRate = false,
  maskAmount = false,
}: Amount & {
  isMinorUnitValue?: boolean;
  forcePlusSign?: boolean;
  withNoMinorUnitDigits?: boolean;
  isExchangeRate?: boolean;
  lang?: SupportedLanguages;
  maskAmount?: boolean;
}): string => {
  if (maskAmount) {
    const formatter = new Intl.NumberFormat(lang, {
      currency,
      currencyDisplay,
      style: 'currency',
    });
    const parts = formatter.formatToParts(0);
    const symbolPart = parts.find((part) => part.type === 'currency');
    const symbol = symbolPart ? symbolPart.value : '';
    const symbolPosition = parts.findIndex((part) => part.type === 'currency');

    return symbolPosition === 0 ? `${symbol} *****` : `***** ${symbol}`;
  }

  const majorUnitValue = isMinorUnitValue
    ? toMajorCurrencyUnit({
        amount: value,
        currency: currency as Dinero.Currency,
      })
    : value;

  let minimumFractionDigits: number | undefined;

  if (withNoMinorUnitDigits) {
    minimumFractionDigits = 0;
  } else if (isExchangeRate) {
    minimumFractionDigits = 4;
  } else {
    // By default, allow the currency default amount to be used
    minimumFractionDigits = undefined;
  }
  const amount = majorUnitValue.toLocaleString(lang, {
    currency,
    currencyDisplay,
    minimumFractionDigits,
    style: 'currency',
  });

  if (forcePlusSign && type === 'payin') {
    return `+${STARTS_WITH_DIGIT_REGEX.test(amount) ? '' : ' '}${amount}`;
  }

  return amount;
};

export const formatAmountToParts = ({
  currency,
  forcePlusSign,
  type,
  value,
}: Amount & {
  forcePlusSign?: boolean;
}): string[] => {
  const formattedAmount = formatAmount({
    currency,
    forcePlusSign,
    type,
    value,
  });
  return formattedAmount.split(formattedAmount.includes('.') ? '.' : ',');
};
