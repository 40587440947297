import { isMatch, type Matcher } from 'react-day-picker';
import {
  addYears,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachYearOfInterval,
  endOfYear,
  isSameMonth,
  isSameQuarter,
  isSameYear,
  startOfYear,
  subYears,
} from 'date-fns';

import { type DatePickerProps, type DatePickerViewMode } from '../../';
import locales from './locales';

export const getHeaderTitle = (
  mode: DatePickerViewMode,
  period: Date,
): string => {
  switch (mode) {
    case 'year': {
      const [prev, next] = [subYears(period, 2), addYears(period, 9)];
      return `${prev.getFullYear()} - ${next.getFullYear()}`;
    }
    case 'quarter':
    case 'month': {
      return period.getFullYear().toString();
    }
    default:
      throw new Error('Invalid mode');
  }
};

export const getItems = (mode: DatePickerViewMode, period: Date): Date[] => {
  switch (mode) {
    case 'year': {
      const [start, end] = [subYears(period, 2), addYears(period, 9)];

      return eachYearOfInterval({ end, start });
    }
    case 'quarter': {
      const [start, end] = [startOfYear(period), endOfYear(period)];

      return eachQuarterOfInterval({ end, start });
    }
    case 'month': {
      const [start, end] = [startOfYear(period), endOfYear(period)];

      return eachMonthOfInterval({ end, start });
    }
    default:
      throw new Error('Invalid mode');
  }
};

export const getTodayLabel = (mode: DatePickerViewMode): string => {
  switch (mode) {
    case 'year':
    case 'month':
      return locales.today;
    case 'quarter': {
      return locales.todayQuarter;
    }
    default:
      throw new Error('Invalid mode');
  }
};

const matcherToArray = (
  matcher: Matcher | Matcher[] | undefined,
): Matcher[] => {
  if (Array.isArray(matcher)) {
    return [...matcher];
  } else if (matcher !== undefined) {
    return [matcher];
  } else {
    return [];
  }
};

export const getItemDisabled = (
  item: Date,
  modifiers?: DatePickerProps['modifiers'],
): boolean => {
  if (!modifiers) {
    return false;
  }

  const disabledMatchers = matcherToArray(modifiers.disabled);

  return isMatch(item, disabledMatchers);
};

export const getItemSelected = (
  item: Date,
  period: DatePickerViewMode,
  value?: Date,
): boolean => {
  if (!value) {
    return false;
  }
  switch (period) {
    case 'year': {
      return isSameYear(item, value);
    }
    case 'quarter': {
      return isSameQuarter(item, value);
    }
    case 'month': {
      return isSameMonth(item, value);
    }
    default:
      throw new Error('Invalid mode');
  }
};
