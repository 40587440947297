import { useIntl } from 'react-intl';
import { forwardRef } from '@chakra-ui/react';

import { Table } from '../../Table';
import { type RowProps } from '../../Table/parts';

export type AtdManagementProps = RowProps;

export const AtdManagement = forwardRef<AtdManagementProps, 'div'>(
  (props, ref) => {
    const intl = useIntl();

    const locales = {
      label: intl.formatMessage({
        id: 'subscription.fees_modal.atd_management.label',
      }),
      value: intl.formatMessage({
        id: 'subscription.fees_modal.atd_management.value',
      }),
    };

    return (
      <Table.Row {...props} ref={ref}>
        <Table.CellText>{locales.label}</Table.CellText>
        <Table.CellAmount>{locales.value}</Table.CellAmount>
      </Table.Row>
    );
  },
);
