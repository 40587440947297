import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    next: 'Next Month',
    previous: 'Previous Month',
    today: 'Today',
    todayQuarter: 'This quarter',
  },
  fr: {
    next: 'Mois suivant',
    previous: 'Mois précédent',
    today: "Aujourd'hui",
    todayQuarter: 'Ce trimestre',
  },
} as const);
