import { type FC, type ReactNode, useRef } from 'react';
import { DidomiSDK, type IDidomiObject } from '@didomi/react';

import webView from 'common/webView';
import { logEvent } from 'features/Analytics/analytics';
import logger from 'helpers/logger';

import { load } from './analytics';
import Context from './Context';
// eslint-disable-next-line import/extensions
import segmentSnippet from './segmentSnippet.js';

const SEGMENT_IO = 'Segment.io';
const EVENT_CONSENT_CHANGED = 'Segment Integrations Preference Updated';

let integrations: Record<string, boolean> = { All: false };

export const DIDOMI_CONFIG_FALLBACK = {
  always_on: ['BigQuery_2', 'Google Tag Manager'],
  'Segment Mappings': {
    'facebook-A8enZGfW': [
      'Facebook Pixel',
      'Facebook Pixel Server Side',
      'Facebook Conversions API - Marketing Website',
      'Facebook Conversions API',
    ],
    google: [
      'DoubleClick Floodlight',
      'Google AdWords New',
      'Google Ads (Gtag)',
    ],
    'googleana-4TXnJigR': [
      'Google Analytics',
      'Google Analytics 4',
      'Actions Google Analytics 4',
    ],
    'intercom-U4Zwpdz8': ['Intercom'],
    'linkedin-ZtrLMW7M': ['Linkedin Insight Tag'],
    'matomo-J77TAWAM': ['Matomo'],
    microsoft: ['Bing Ads'],
    mixpanel: ['Mixpanel', 'Mixpanel (Actions)'],
    segment: [SEGMENT_IO],
    'snapinc-yhYnJZfT': ['Snapchat Conversions API'],
    'tiktok-KZAUQLZ9': ['TikTok'],
    twitter: ['Twitter'],
  },
};

interface AnalyticsProps {
  children: ReactNode;
  consentKey: string;
  writeKey: string;
}

const Analytics: FC<AnalyticsProps> = ({ children, consentKey, writeKey }) => {
  const segmentLoaded = useRef(false);
  segmentSnippet();

  const didomiRef = useRef<null | IDidomiObject>(null);

  const openCookiesSettings = () => {
    if (didomiRef.current) {
      didomiRef.current.preferences.show();
    }
  };

  const updateIntegrationsObject = (cancelConsent = false) => {
    const didomi = didomiRef.current;

    if (didomi === null) {
      logger.error(
        'We tried to load segment before getting consent, it means we broke something',
      );
      return;
    }

    const status = didomi.getCurrentUserStatus();

    const alwaysOn = DIDOMI_CONFIG_FALLBACK.always_on;
    const mapping: Record<string, string[]> =
      DIDOMI_CONFIG_FALLBACK['Segment Mappings'];

    integrations = { All: false };

    alwaysOn.forEach((integration: string) => {
      integrations[integration] = cancelConsent ? false : true;
    });

    if (status && status.vendors) {
      const { vendors } = status;
      const vendorsKeys = Object.keys(vendors);

      vendorsKeys.forEach((vendorApiId: string) => {
        const vendorValue = vendors[vendorApiId];

        if (vendorValue) {
          mapping[vendorApiId]?.forEach((integration: string) => {
            integrations[integration] = cancelConsent
              ? false
              : Boolean(vendorValue.enabled);
          });
        }
      });
    }
  };

  const loadSegmentAndDestinations = () => {
    updateIntegrationsObject();

    if (integrations[SEGMENT_IO]) {
      segmentLoaded.current = true;
      load(writeKey || '', { integrations });

      // As Segment is loaded, we can send a consent changed event
      const event = {
        name: EVENT_CONSENT_CHANGED,
        properties: { integrations },
        usePrefix: false,
      };

      logEvent(event);
    }
  };

  const onDidomiReady = (didomi: IDidomiObject) => {
    didomiRef.current = didomi;

    /**
     * In the webView, consent is collected from the native app (with the tracking prompt).
     * If the user update their consent anytime, we have to update it immediately.
     */
    const shouldUserStatusBeCollected =
      didomi.shouldUserStatusBeCollected() || webView.isInWebView;

    // consent already has been collected, we can load segment
    if (!shouldUserStatusBeCollected) {
      loadSegmentAndDestinations();
      return;
    }

    if (webView.isInWebView) {
      const trackingStatus = webView.config?.trackingStatus;

      /**
       * We allow tracking to Mixpanel for users that explicitly authorized it (on iOS >= 14.5)
       * or who use an OS where this policy isn't enforced (Android and iOS < 14.5)
       */
      if (trackingStatus === 'authorized' || trackingStatus === 'unavailable') {
        didomi.setUserAgreeToAll();
      } else {
        didomi.setUserDisagreeToAll();
      }
    }
  };

  return (
    <Context.Provider
      value={{
        openCookiesSettings,
      }}
    >
      {children}

      <DidomiSDK
        apiKey={consentKey}
        config={{
          tagManager: {
            provider: 'gtm',
          },
        }}
        onConsentChanged={() => {
          if (segmentLoaded.current) {
            /**
             * Segment has already been loaded, we will have to refresh the page
             * If the user decides to remove Segment we need to cancel all consent for good measure
             */
            updateIntegrationsObject();

            // We send a last consent changed event before Segment gets disabled
            if (!integrations[SEGMENT_IO]) {
              updateIntegrationsObject(true);

              const event = {
                name: EVENT_CONSENT_CHANGED,
                properties: { integrations },
                usePrefix: false,
              };

              logEvent(event, () => document.location.reload());
            } else {
              // Sending a new consent changed event will happen after the reload
              document.location.reload();
            }
          } else {
            loadSegmentAndDestinations();
          }
        }}
        onReady={onDidomiReady}
      />
    </Context.Provider>
  );
};

export default Analytics;
