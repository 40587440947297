import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Center, Flex, Grid, VStack } from '@chakra-ui/react';

import {
  type OutstandingAgreement,
  OutstandingAgreementType,
} from '__generated__/GQL';
import asBentoPage from 'common/bento/hoc/asBentoPage';
import { BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';
import { apolloClient } from 'common/graphql/clients';
import Button from 'components/_core/Button';
import Text from 'components/_core/Text';
import Loader from 'components/Loader';
import Header from 'features/Onboarding/components/Header';
import OBBackButton from 'features/Onboarding/components/OBBackButton/OBBackButton';
import { YousignFrame } from 'features/Onboarding/components/YousignFrame';
import { VerificationModuleDataDocument } from 'features/Onboarding/modules/Verification/graphql/queries/verificationModuleData.gql';

import { CreatePowerOfAttorneyNotaryFourezSignatureLinkDocument } from './createPowerOfAttorneyNotaryFourezSignatureLink.gql';
import locales from './locales';

interface DocumentSignatureProps {
  companyProfileId: string;
  agreement: Pick<OutstandingAgreement, 'type'>;
  onNext: () => void;
}
const DocumentSignature = asBentoPage<DocumentSignatureProps>(
  ({ agreement, companyProfileId, onNext, onPrev }) => {
    if (agreement.type !== OutstandingAgreementType.NotaryFourez) {
      throw new Error(`Agreements of type ${agreement.type} are not supported`);
    }

    const [isRefetching, setIsRefetching] = useState(false);

    const [
      createPowerOfAttorneyNotaryFourezSignatureLink,
      { data, error, loading },
    ] = useMutation(CreatePowerOfAttorneyNotaryFourezSignatureLinkDocument, {
      variables: {
        input: {
          applicationId: companyProfileId,
        },
      },
    });

    if (error) {
      throw error;
    }

    useEffect(() => {
      createPowerOfAttorneyNotaryFourezSignatureLink();
    }, [createPowerOfAttorneyNotaryFourezSignatureLink]);

    const [success, setSuccess] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);

    const isLarge = useLayoutSizeQuery(BentoLayoutSize.lg);

    const onContinue = async () => {
      setIsRefetching(true);
      await apolloClient.refetchQueries({
        include: [VerificationModuleDataDocument],
      });
      setIsRefetching(false);
      onNext();
    };

    return (
      <Grid
        height="100dvh"
        {...(isLarge
          ? {
              templateAreas: '"document text"',
              templateColumns: `1fr 480px`,
            }
          : {
              templateAreas: '"text" "document" "cta"',
              templateColumns: '1fr',
              templateRows: 'auto 1fr auto',
            })}
        width="100vw"
      >
        <VStack
          align="stretch"
          background="white"
          gridArea="text"
          padding={{
            base: 'space-16',
            lg: 'space-40',
          }}
          spacing={{
            base: 'space-16',
            lg: 'space-48',
          }}
        >
          {isLarge ? (
            <Button
              alignSelf="flex-end"
              icon="cross"
              iconPosition="left"
              onClick={onPrev}
              size="sm"
              variant="tertiary"
            >
              {locales.exit}
            </Button>
          ) : (
            <Grid templateColumns="40px 1fr 40px">
              <OBBackButton onClick={onPrev} />
              <Center>
                <Text>{locales.pageTitle}</Text>
              </Center>
              <Box />
            </Grid>
          )}

          <Box>
            <Header
              marginBottom={{
                base: 0,
                lg: 'space-32',
              }}
              title={locales.documentTitle}
            />
            {isLarge ? (
              <>
                <Text>{locales.description}</Text>
                <Flex justifyContent="flex-end" marginTop="space-32">
                  <Button
                    isDisabled={!success}
                    isLoading={isRefetching}
                    onClick={onContinue}
                  >
                    {locales.ctaContinue}
                  </Button>
                </Flex>
              </>
            ) : null}
          </Box>
        </VStack>

        <Box
          background="grey.3"
          gridArea="document"
          height={{
            base: 'auto',
            lg: '100vh',
          }}
        >
          {loading ||
          !data?.createPowerOfAttorneyNotaryFourezSignatureLink
            .signatureLink ? (
            <Loader />
          ) : (
            <YousignFrame
              fullscreen={fullscreen}
              onLeaveFullscreen={() => {
                setFullscreen(false);
              }}
              onSuccess={() => {
                setFullscreen(false);
                setSuccess(true);
              }}
              signatureLink={
                data?.createPowerOfAttorneyNotaryFourezSignatureLink
                  .signatureLink
              }
            />
          )}
        </Box>

        {isLarge ? null : (
          <Box gridArea="cta" padding="space-16">
            <Button
              icon="arrow-right"
              iconPosition="right"
              isDisabled={!success}
              isLoading={isRefetching}
              onClick={onContinue}
              width="full"
            >
              {locales.ctaContinue}
            </Button>
          </Box>
        )}
      </Grid>
    );
  },
);

export default DocumentSignature;
