import { SdkEnvironment } from '__generated__/GQL';

import remoteConfigDefaults from './sharedRemoteConfigDefaults';

const graphqlHost = 'api.shine.fr';

const ONE_HOUR_MS = 60 * 60 * 1000;

const config = {
  assurupWidgetUrl:
    'https://app.assurup.com/quote?partnerId=49&utm_medium=web&utm_source=quote&utm_campaign=quote',
  authenticatedSchemaSubscriptionsUri: `wss://${graphqlHost}/v2/subscriptions`,
  cannyAppId: '5c77bd1a6d8cd3449b277c90',
  didomiKey: '5b58c4e5-abc4-40d7-bf7d-fb1387527333',
  downloadHook: 'https://hooks.zapier.com/hooks/catch/2912704/oygtlfb/',
  firebase: {
    apiKey: 'AIzaSyAFb38ulbWrpaDCOMxHwcjb2jVDTYvmzII',
    appId: '1:692320681201:web:bfdcc85439b46e71',
    authDomain: 'shine-163816.firebaseapp.com',
    databaseURL: 'https://shine-163816.firebaseio.com',
    messagingSenderId: '692320681201',
    projectId: 'shine-163816',
    storageBucket: 'shine-163816.appspot.com',
  },
  fourthlineCdnHost: 'https://v.fourthline.com',
  fourthlineHostedFlowHost: 'https://shine.app.fourthline.com/v1',
  gcloudApiKey: 'AIzaSyCDSXSq4F8zIgV6rCFQvNIh1T8L6Qezit0',
  graphqlPublicUri: `https://${graphqlHost}/v2/graphql-public`,
  graphqlUri: `https://${graphqlHost}/v2/graphql`,
  identityCheckSdkEnvironment: SdkEnvironment.Production,
  intercomAppId: 'e6lrdjky',
  publicSchemaSubscriptionsUri: `wss://${graphqlHost}/v2/subscriptions-public`,
  publicWebsiteUrl: 'https://www.shine.fr',
  remoteConfigDefaults,
  remoteConfigLogLevel: 'silent',
  remoteConfigMinFetchIntervalMs: 12 * ONE_HOUR_MS,
  segmentKey: 'Sdx0nv9ay3vhuniKJAeHCmJBr3AaZfny',
  sentryDsn:
    'https://fd4649e3b86e4ec99a554468765bb721@o1089553.ingest.sentry.io/6105000',
  shineApiHost: 'https://api.shine.fr/v2',

  shineAppHost: 'https://app.shine.fr',

  shineStartTypeformId: 'gHuJZV',
  // Signup zap URL: https://zapier.com/app/editor/73892237
  signupZapierHook: 'https://hooks.zapier.com/hooks/catch/2912704/otyeauo/',
  /**
   * This key is linked to the "Shine" Stripe account
   * that we use to make users pay for their onboarding.
   */
  stripeKey: 'pk_live_vLINvjhWxKztALvVqofnI2E1',
};

export default config;
