import { useEffect, useMemo, useState } from 'react';
import {
  CircularProgress,
  CircularProgressLabel,
  type CircularProgressProps,
  forwardRef,
} from '@chakra-ui/react';
import { type IconName } from '@shinetools/sunshine-icons';
import { Icon } from '@shinetools/sunshine-universal';

import { useQuota } from '../context';

export type ProgressProps = CircularProgressProps & {
  icon: IconName;
};

export const Progress = forwardRef<ProgressProps, 'div'>((props, ref) => {
  const { __css: css, icon, max = 0, value: propValue = 0, ...rest } = props;

  const [value, setValue] = useState(0);
  const { theme } = useQuota();

  useEffect(() => {
    const animationDelay = setTimeout(() => setValue(propValue), 150);
    return () => clearTimeout(animationDelay);
  }, [setValue, propValue]);

  const progress = useMemo<Pick<CircularProgressProps, 'max' | 'value'>>(() => {
    if (max === 0) {
      return {
        max: 1,
        value: value,
      };
    }

    return {
      max,
      value: Math.min(value, max),
    };
  }, [value, max]);

  return (
    <CircularProgress
      __css={{
        ...theme.progress,
        ...css,
      }}
      capIsRound
      className="quota__progress"
      color="green.600"
      max={progress.max}
      min={0}
      sx={{
        '& > svg > circle:last-child': {
          transitionDuration: '1.5s',
          transitionTimingFunction: 'ease-out',
        },
      }}
      thickness={8}
      trackColor="grey.300"
      value={progress.value}
      {...rest}
      ref={ref}
    >
      {icon ? (
        <CircularProgressLabel __css={{ ...theme.progress_icon }}>
          <Icon icon={icon} margin="auto" />
        </CircularProgressLabel>
      ) : null}
    </CircularProgress>
  );
});
