import { type FC } from 'react';
import { useIntl } from 'react-intl';

import { Quota, type QuotaProps } from '../components/Quota';
import { useQuota } from '../context';

export type WithdrawalQuotaProps = QuotaProps;

export const WithdrawalQuota: FC<WithdrawalQuotaProps> = (props) => {
  const { fees, planName, quotas } = useQuota();
  const intl = useIntl();

  const quota = quotas.withdrawalMonthQuota;
  const fee = fees.additionalWithdrawalEuro;
  const feeNonEuro = fees.additionalWithdrawalNonEuro;

  const hasExceeded = quota.used > quota.limit;
  const exceeding = 0;

  const locales = {
    additional_fee: intl.formatMessage(
      {
        id: 'subscription.overview.quotas.withdrawal.additional_fee',
      },
      {
        fixed: fee.fixed / 100,
        variable: (feeNonEuro.variable ?? 0) * 100,
      },
    ),
    description: intl.formatMessage(
      {
        id: 'subscription.overview.quotas.withdrawal.description',
      },
      {
        limit: quota.limit,
        plan: planName,
        used: quota.used,
      },
    ),
    exceeding: intl.formatMessage(
      {
        id: 'subscription.overview.quotas.exceeding',
      },
      {
        exceeding,
      },
    ),
    label: intl.formatMessage({
      id: 'subscription.overview.quotas.withdrawal.label',
    }),
  };

  return (
    <Quota isExpandable {...props}>
      <Quota.Body>
        <Quota.Progress icon="cash" max={quota.limit} value={quota.used} />

        <Quota.Content>
          <Quota.Label>{locales.label}</Quota.Label>
          <Quota.Description>{locales.description}</Quota.Description>
        </Quota.Content>

        {/* TODO: fetch exceeding quota once available from billing-service */}
        {/* <Quota.Exceeding>{locales.exceeding}</Quota.Exceeding> */}
      </Quota.Body>

      <Quota.Footer>
        <Quota.AdditionalFees>{locales.additional_fee}</Quota.AdditionalFees>
        {hasExceeded ? <Quota.IncreaseLimitButton /> : null}
      </Quota.Footer>
    </Quota>
  );
};
