/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8468%3A42058&t=BMXlpcFDV83xIao0-1
 */

import { anatomy, type PartsStyleObject } from '@chakra-ui/theme-tools';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parts = anatomy('SunshineToast').parts(
  'container',
  'text',
  'closeButton',
);

type Parts = typeof parts;

const baseStyle: PartsStyleObject<Parts> = {
  closeButton: {
    flexShrink: 0,
  },
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 'radius-8',
    boxShadow: 'shadow-small-1',
    display: 'flex',
    gap: 'space-16',
    paddingLeft: 'space-16',
    paddingRight: 'space-8',
    paddingY: 'space-12',
  },
  text: {
    flex: 1,
  },
};

export type Variant = 'error' | 'success';

const variants: Record<Variant, PartsStyleObject<Parts>> = {
  error: {},
  success: {},
};

const SunshineToastTheme = {
  baseStyle,
  variants,
};

export default SunshineToastTheme;
