import {
  type BentoModule,
  type BentoModuleInput,
} from 'common/bento/types/BentoModule';

/**
 * The simplest implementation of a Module is a React Component that takes an `onDone` callback.
 *
 * This function is defined to allow future extension of our Modules' features without breaking existing implementations.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const asBentoModule = <TProps extends Record<string, any>>(
  Component: BentoModuleInput<TProps>,
): BentoModule<TProps> => {
  return Component;
};

export default asBentoModule;
