import { useIntl } from 'react-intl';
import { forwardRef } from '@chakra-ui/react';

import { Table } from '../../Table';
import { type RowProps } from '../../Table/parts';
import { useFees } from '../context';

export type CheckDepositInvalidProps = RowProps;

export const CheckDepositInvalid = forwardRef<CheckDepositInvalidProps, 'div'>(
  (props, ref) => {
    const intl = useIntl();
    const { fees, toFixed, toVariable } = useFees();

    const locales = {
      label: intl.formatMessage({
        id: 'subscription.fees_modal.check_deposit_invalid.label',
      }),
      value: intl.formatMessage(
        {
          id: 'subscription.fees_modal.check_deposit_invalid.value',
        },
        {
          fixed: toFixed(fees.checkDepositInvalid),
          variable: toVariable(fees.checkDepositInvalid),
        },
      ),
    };

    return (
      <Table.Row {...props} ref={ref}>
        <Table.CellText>{locales.label}</Table.CellText>
        <Table.CellAmount>{locales.value}</Table.CellAmount>
      </Table.Row>
    );
  },
);
