import { type ProviderPlanId } from '__generated__/GQL';

import { type PlanChangeSuccessDialogFragment } from '../../overview.gql';

/**
 * Returns metadata about a plan change
 */
export const getPlanChangeData = (
  switchedFromPlan: ProviderPlanId,
  { currentPlan, subscriptionPlan }: PlanChangeSuccessDialogFragment,
) => {
  if (!subscriptionPlan) {
    throw new Error('No subscription plan found');
  }

  const plans = [
    ...currentPlan.downgradablePlans,
    currentPlan,
    ...currentPlan.upgradablePlans,
  ];

  const getPlan = (_providerPlanId: ProviderPlanId) => {
    const plan = plans.find((p) => p.providerPlanId === _providerPlanId);

    if (!plan) {
      throw new Error(`Plan ${_providerPlanId} not found`);
    }

    return plan;
  };

  /**
   * Handles a downgrade.
   * The only way to know the target plan is to check the `scheduledDowngrade` field (all other endpoints continue to return the pre-downgrade plan).
   */
  if (subscriptionPlan.scheduledDowngrade) {
    return {
      isDowngrade: true,
      nextPlan: getPlan(subscriptionPlan.scheduledDowngrade as ProviderPlanId),
      previousPlan: getPlan(currentPlan.providerPlanId),
    };
  }

  /**
   * This case is relatively hypothetical: the user just downgraded, but it was not marked a a "scheduledDowngrade".
   * Maybe if the user downgrades on the day of the billing cycle, the downgrade is immediate, so we've handled it here just in case.
   */
  if (
    currentPlan.upgradablePlans.find(
      (plan) => plan.providerPlanId === switchedFromPlan,
    )
  ) {
    return {
      isDowngrade: true,
      nextPlan: getPlan(currentPlan.providerPlanId),
      previousPlan: getPlan(switchedFromPlan),
    };
  }

  /**
   * Handles an upgrade.
   */
  if (
    currentPlan.downgradablePlans.find(
      (plan) => plan.providerPlanId === switchedFromPlan,
    )
  ) {
    const nextPlan = getPlan(currentPlan.providerPlanId);
    const previousPlan = getPlan(switchedFromPlan);

    return {
      hasUnlockedNewPhysicalCards:
        nextPlan.paymentCard.type !== previousPlan.paymentCard.type ||
        (previousPlan.limits.physicalPaymentCard.value === 0 &&
          nextPlan.limits.physicalPaymentCard.value > 0),
      isDowngrade: false,
      nextPlan,
      previousPlan,
    };
  }

  return null;
};
