import { z } from 'zod';

import localStorageObjectSchema from 'common/zod/localStorageObjectSchema';

import {
  FOURTHLINE_ERRORS,
  type FourthlineErrorCode,
} from './helpers/fourthlineNativeSDK';

/**
 * The web-app and app versions of this file are meant to be mirrors.
 * Please keep them both updated at all times.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const messageWebToNativeSchema = z.union([
  z.object({
    action: z.enum([
      'WEBVIEW_LOADED',
      'LOGOUT_REQUESTED',
      'ONBOARDING_ABORTED',
      'NATIVE_ID_CHECK_REQUESTED',
      'OPEN_INTERCOM_REQUESTED',
      'AUTHENTICATION_EXPIRED',
      'ACCOUNT_SWITCH_REQUESTED',
      'CLOSE_WEBVIEW_REQUESTED',
    ]),
  }),

  z.object({
    action: z.literal('NATIVE_FOURTHLINE_QES_REQUESTED'),
    payload: z.object({
      verificationCode: z.string(),
    }),
  }),

  z.object({
    action: z.enum(['ONBOARDING_COMPLETED']),
    payload: z.object({
      companyProfileId: z.string(),
      companyUserId: z.string(),
    }),
  }),
]);

export type MessageWebToNative = z.infer<typeof messageWebToNativeSchema>;

export const messageNativeToWebSchema = z.union([
  z.object({
    action: z.enum([
      'NATIVE_ID_CHECK_CANCELED',
      'NATIVE_ID_CHECK_COMPLETED',
      'NATIVE_FOURTHLINE_QES_COMPLETED',
    ]),
  }),
  z.object({
    action: z.literal('NATIVE_FOURTHLINE_QES_FAILED'),
    payload: z.object({
      errorCode: z
        .number()
        .refine(function (code): code is FourthlineErrorCode {
          return code in FOURTHLINE_ERRORS;
        }),
      errorDescription: z.string(),
    }),
  }),
  z.object({
    action: z.literal('NATIVE_ID_CHECK_FAILED'),
    /**
     * The `payload` property for `NATIVE_ID_CHECK_FAILED` was released in mobile app in 1.195.0.
     * - Before 1.195.0, it was not set.
     * - Starting with 1.195.0, it's the Onfido error code (eg. `Onfido.OnfidoFlowError.cameraPermission`, `Onfido.OnfidoFlowError.microphonePermission`, etc.)
     */
    payload: z.string().optional(),
  }),
]);

export type MessageNativeToWeb = z.infer<typeof messageNativeToWebSchema>;

const webViewConfigSchema = z.object({
  // This property was fully released in mobile app in 1.196.0.
  appVersion: z.string().optional(),
  isEmbedInAppLayout: z.boolean().optional(),
  lang: z.string().optional(),
  trackingStatus: z.enum([
    'unavailable',
    'denied',
    'authorized',
    'restricted',
    'not-determined',
  ]),
});

export const parseStringifiedWebViewConfig = (
  stringifiedConfig: string | null,
) =>
  localStorageObjectSchema
    .pipe(webViewConfigSchema)
    .safeParse(stringifiedConfig);

export type WebViewConfig = z.infer<typeof webViewConfigSchema>;
