import { type FC } from 'react';
import { chakra, type HTMLChakraProps } from '@chakra-ui/react';

import { type DatePickerViewMode } from '../../..';
import { getItemText } from './utils';

interface PeriodPickerItemProps extends HTMLChakraProps<'button'> {
  viewMode: DatePickerViewMode;
  date: Date;
  isSelected: boolean;
  isDisabled: boolean;
}

const PeriodPickerItem: FC<PeriodPickerItemProps> = ({
  date,
  isDisabled = false,
  isSelected = false,
  viewMode,
  ...props
}) => (
  <chakra.button {...props} aria-selected={isSelected} disabled={isDisabled}>
    {getItemText(viewMode, date)}
  </chakra.button>
);

export default PeriodPickerItem;
