import { type FC, useEffect } from 'react';
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { Feature } from '@shinetools/pricing-plan-library';
import { Typography } from '@shinetools/sunshine-universal';

import { type ProviderPlanId } from '__generated__/GQL';
import useCompanyContext from 'common/hooks/useCompanyContext';
import { useCurrentPricingPlan } from 'common/hooks/useCurrentPricingPlan';
import Button from 'components/_core/Button';
import { capitalize } from 'helpers/string';
import * as Layout from 'layouts/ContentLayout';

import { InvoicesTable } from '../../components/InvoicesTable';
import { ModalFees } from '../../components/ModalFees';
import * as routes from '../../routes';
import { OverviewHeader } from './components/OverviewHeader';
import { OverviewInsuranceCoverage } from './components/OverviewInsuranceCoverage';
import { OverviewQuotas } from './components/OverviewQuotas';
import { OverviewScheduledDowngrade } from './components/OverviewScheduledDowngrade';
import { PlanChangeSuccessDialog } from './components/PlanChangeSuccessDialog';
import * as GQL from './overview.gql';

export const Overview: FC = () => {
  const { companyProfileId } = useCompanyContext();
  const intl = useIntl();

  const modalFees = useDisclosure();

  /**
   * `switched-from-plan` is a query param informing that we just went through an upgrade or downgrade flow.
   * We'll use it to display a success dialog to the user.
   */
  const switchedFromPlan = new URLSearchParams(useLocation().search).get(
    'switched-from-plan',
  );

  const { isFeatureAvailable } = useCurrentPricingPlan();

  const { data, loading } = useQuery(
    GQL.SubscriptionManagementOverviewDocument,
    {
      fetchPolicy: 'cache-and-network',
      skip: !companyProfileId,
      variables: {
        companyProfileId: companyProfileId as string,
        includePlanChangeData: !!switchedFromPlan,
      },
    },
  );

  const history = useHistory();

  const successDialogDisclosure = useDisclosure({
    onClose() {
      history.replace(routes.root);
    },
  });

  useEffect(() => {
    if (!data || !switchedFromPlan) {
      return () => {};
    }

    const openSuccessDialogTimeout = setTimeout(() => {
      successDialogDisclosure.onOpen();
    }, 300);

    return () => {
      clearTimeout(openSuccessDialogTimeout);
    };
  }, [data, successDialogDisclosure, history, switchedFromPlan]);

  const currentPlan = data?.viewer.company.currentPlan;
  const invoices = data?.viewer.company.subscriptionPlan?.invoices?.edges;

  return (
    <IntlProvider
      {...intl}
      defaultRichTextElements={{
        ...intl.defaultRichTextElements,
        discreet: (chunks) => (
          <Typography.Text tag="span" variant="discreet">
            {chunks}
          </Typography.Text>
        ),
        modal_fees: (chunks) => (
          <Typography.Link onPress={modalFees.onOpen}>{chunks}</Typography.Link>
        ),
      }}
    >
      {data ? (
        <PlanChangeSuccessDialog
          {...successDialogDisclosure}
          planChangeQuery={data.viewer.company}
          switchedFromPlan={switchedFromPlan as ProviderPlanId | null}
        />
      ) : null}
      <Layout.Root>
        <Layout.Body size="md">
          <Flex direction="column" gap="space-48">
            {isFeatureAvailable(Feature.InsuranceAssistance) ? (
              <OverviewInsuranceCoverage data={data} />
            ) : null}

            {companyProfileId && data ? (
              <OverviewScheduledDowngrade
                companyProfileId={companyProfileId}
                data={data}
              />
            ) : null}

            <OverviewHeader data={data} />

            <Flex direction="column" gap="space-24">
              <Flex direction="column" gap="space-2">
                <Typography.Text bold size="large">
                  <FormattedMessage id="subscription.overview.usage.title" />
                </Typography.Text>
                <Typography.Text variant="secondary">
                  <FormattedMessage id="subscription.overview.usage.description" />
                </Typography.Text>
              </Flex>

              <OverviewQuotas data={data} loading={loading && !data} />

              <Typography.Text variant="secondary">
                <FormattedMessage
                  id="subscription.overview.fees"
                  values={{
                    plan: currentPlan?.providerPlanId
                      ? capitalize(currentPlan.providerPlanId)
                      : '',
                  }}
                />
              </Typography.Text>
            </Flex>

            <Flex direction="column" gap="space-24">
              <Flex alignItems="center" justifyContent="space-between">
                <Typography.Text bold size="large">
                  <FormattedMessage id="subscription.overview.invoices.label" />
                </Typography.Text>

                <Button as={Link} to={routes.invoices} variant="inline-primary">
                  <FormattedMessage id="subscription.overview.invoices.link" />
                </Button>
              </Flex>

              <InvoicesTable invoices={invoices} loading={loading && !data} />
            </Flex>
          </Flex>
        </Layout.Body>

        {companyProfileId && currentPlan && modalFees.isOpen ? (
          <ModalFees
            {...modalFees}
            companyProfileId={companyProfileId}
            providerPlanId={currentPlan.providerPlanId}
          />
        ) : null}
      </Layout.Root>
    </IntlProvider>
  );
};
