import 'react-day-picker/dist/style.css';

import { type FC } from 'react';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { format } from 'date-fns';

import SunshineDatePicker, {
  type DatePickerProps,
} from 'components/_core/SunshineDatePicker';
import { type Format } from 'helpers/date';

import locales from './locales';

import * as Form from '..';

type SunshineInputDatePickerProps = DatePickerProps & {
  dateFormat?: Format;
  inputProps?: Omit<Form.InputProps, 'onChange' | 'value'>;
};

const SunshineInputDatePicker: FC<SunshineInputDatePickerProps> = ({
  dateFormat = 'P',
  inputProps = {},
  onChange,
  value,
  ...props
}) => (
  <Popover>
    {({ isOpen, onClose }) => (
      <>
        <PopoverTrigger>
          <Form.Input
            {...inputProps}
            isReadOnly
            value={
              value
                ? format(value, dateFormat, { locale: locales.dateFnsLocale })
                : ''
            }
          />
        </PopoverTrigger>

        <PopoverContent>
          <PopoverBody padding={0}>
            <SunshineDatePicker
              initialFocus={isOpen}
              onChange={(date) => {
                onChange(date);
                onClose();
              }}
              value={value}
              {...props}
            />
          </PopoverBody>
        </PopoverContent>
      </>
    )}
  </Popover>
);

export default SunshineInputDatePicker;
