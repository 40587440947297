import { type Address } from '__generated__/GQL';

const GOOGLE_MAPS_SCRIPT_BASE_URL = 'https://maps.googleapis.com/maps/api/js';

export const loadGoogleMapsScript = async ({
  apiKey,
  language,
}: {
  apiKey: string;
  language: string;
}): Promise<void> => {
  if (window.google?.maps?.places) {
    return Promise.resolve();
  }

  const searchParams = new URLSearchParams({
    key: apiKey,
    language,
    libraries: 'places',
  });

  const googleMapsScriptUrl = `${GOOGLE_MAPS_SCRIPT_BASE_URL}?${searchParams.toString()}`;

  const [scriptElement] = document.querySelectorAll(
    `script[src*="${GOOGLE_MAPS_SCRIPT_BASE_URL}"]`,
  );

  if (scriptElement) {
    return new Promise((resolve) => {
      scriptElement.addEventListener('load', () => resolve());
    });
  }

  const el = document.createElement('script');
  el.src = googleMapsScriptUrl;

  const promise = new Promise<void>((resolve) => {
    el.addEventListener('load', () => resolve());
  });

  document.body.appendChild(el);

  return promise;
};

export const getGoogleMapsEmbedPlaceQueryString = ({
  city,
  street,
  zip,
}: Pick<Address, 'street' | 'zip' | 'city'>) => {
  return encodeURIComponent(`${street},${zip},${city}`);
};
