import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    ctaContinue: 'Continue',
    description:
      'Please review and sign your document before submitting your application.',
    documentTitle: 'Sign the capital deposit application',
    exit: 'Exit',
    fullscrenCTA: 'See in full screen',
    pageTitle: 'Your company',
  },
  fr: {
    ctaContinue: 'Continuer',
    description:
      'Veuillez vérifier puis signer ce document pour soumettre votre dossier.',
    documentTitle: 'Signez la demande de dépôt de capital',
    exit: 'Fermer',
    fullscrenCTA: 'Voir en plein écran',
    pageTitle: 'Votre entreprise',
  },
} as const);

export default locales;
