import { type FC, type PropsWithChildren, type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  type CenterProps,
  SimpleGrid,
} from '@chakra-ui/react';
import { Icon } from '@shinetools/sunshine-universal';

const SubscriptionManagementContainer: FC<PropsWithChildren & CenterProps> = ({
  children,
  ...props
}) => (
  <Center
    alignItems="flex-start"
    paddingX={{ base: 40, lg: 60, xl: 92 }}
    paddingY={{ base: 'space-40', xl: 'space-64' }}
    {...props}
  >
    {children}
  </Center>
);

export const SubscriptionManagementLayout: FC<
  PropsWithChildren<{
    prevRoute: string;
    asideContent?: ReactNode;
  }>
> = ({ asideContent, children, prevRoute }) => {
  const mainContainer = (
    <SubscriptionManagementContainer
      justifyContent={asideContent ? 'flex-end' : 'center'}
    >
      <Box maxWidth={asideContent ? 520 : 1256}>
        <Button
          as={Link}
          leftIcon={<Icon icon="arrow-left" />}
          marginBottom={{ base: 'space-40', xl: 'space-48' }}
          to={prevRoute}
          variant="secondary"
        >
          Retour
        </Button>

        {children}
      </Box>
    </SubscriptionManagementContainer>
  );

  return asideContent ? (
    <SimpleGrid columns={2} height="full">
      {mainContainer}

      <SubscriptionManagementContainer
        backgroundColor="grey.200"
        justifyContent="flex-start"
      >
        {asideContent}
      </SubscriptionManagementContainer>
    </SimpleGrid>
  ) : (
    mainContainer
  );
};
