import { type FC } from 'react';
import { type LinkProps } from 'react-router-dom';
import {
  chakra,
  forwardRef,
  type HTMLChakraProps,
  type ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import * as Form from '../form';
import { unsafe__useNullableRadioGroup } from '../form/Radio/hooks/useRadioGroup';
import CardButton from './components/Button';
import CardCheckboxGroup from './components/CheckboxGroup';
import CardContent from './components/Content';
import CardGroup from './components/Group';
import CardRadioGroup from './components/RadioGroup';
import CardSlot from './components/Slot';
import CardSunshineIcon from './components/SunshineIcon';
import CardText from './components/Text';
import CardTitle from './components/Title';
import { CardContextProvider } from './hooks/useCard';
import { useCardGroup } from './hooks/useCardGroup';
import useSlot from './hooks/useSlot';
import { CLASSNAME } from './utils';

export interface CardParts {
  Button: typeof CardButton;
  CheckboxGroup: typeof CardCheckboxGroup;
  Content: typeof CardContent;
  Group: typeof CardGroup;
  RadioGroup: typeof CardRadioGroup;
  Slot: typeof CardSlot;
  SunshineIcon: typeof CardSunshineIcon;
  Text: typeof CardText;
  Title: typeof CardTitle;
}

type BaseProps = Omit<ThemingProps<'Card'>, 'variant'> &
  Pick<LinkProps, 'href' | 'target' | 'download'> & {
    isActive?: boolean;
    isBoxed?: boolean;
    isDisabled?: boolean;
    to?: LinkProps['to'];
    title?: string;
    isInvalid?: boolean;
  };

type VariantProps =
  | ({ variant?: 'default' } & HTMLChakraProps<'div'>)
  | ({ variant: 'checkbox' } & Omit<HTMLChakraProps<'label'>, 'onChange'> &
      Pick<Form.CheckboxProps, 'id' | 'isChecked' | 'onChange' | 'value'>)
  | ({ variant: 'radio' } & HTMLChakraProps<'label'> &
      Pick<Form.RadioProps, 'id' | 'isChecked' | 'onChange' | 'value'>)
  | ({ variant: 'switch' } & HTMLChakraProps<'label'> &
      Pick<
        Form.SunshineSwitchProps,
        'id' | 'isChecked' | 'onChange' | 'value' | 'defaultChecked'
      >);

export type SunshineCardProps = BaseProps & VariantProps;

const SunshineCard = forwardRef<SunshineCardProps, 'div' | 'a'>(
  (
    {
      children,
      isActive,
      isBoxed = true,
      isDisabled,
      isInvalid,
      title,
      ...props
    },
    ref,
  ) => {
    const isLink = props.as && (props.to || 'href' in props);
    const isActionnable =
      !isDisabled &&
      (['checkbox', 'radio', 'switch'].includes(props.variant ?? '') ||
        props.onClick ||
        isLink);

    const groupProps = useCardGroup();
    const radioGroupProps = unsafe__useNullableRadioGroup();
    const isInvalidFromRadioGroup = radioGroupProps?.isInvalid || false;

    const styles = useMultiStyleConfig('SunshineCard', {
      isActionnable,
      isActive,
      isBoxed: groupProps.isBoxed ?? isBoxed,
      isInvalid: (isInvalidFromRadioGroup ?? isInvalid) && !isDisabled,
    });

    const asideSlot = useSlot({ children, name: 'aside' });
    const actionSlot = useSlot({ children, name: 'action' });

    const contentSlot = (
      <chakra.div flex={1}>
        {title ? <CardTitle>{title}</CardTitle> : null}
        {children}
      </chakra.div>
    );

    /**
     * Card is used in the CardGroup component
     * props.className is used for Intercom (see features/HelpHome)
     */
    const className = [CLASSNAME, props.className].join(' ');

    const containerProps = {
      ...(isActionnable && { tabIndex: 0 }),
      __css: styles,
      className,
      'data-focus-visible-disabled': true,
      disabled: isDisabled,
      invalid: isInvalid && !isDisabled,
    };

    /**
     * "checkbox" variant
     * renders a Form.Checkbox by default in the "action" slot
     */
    if (props.variant === 'checkbox') {
      const { id, isChecked, onChange, value, ...labelProps } = props;

      const checkboxProps = {
        checkboxPosition: 'right',
        id,
        isChecked,
        isDisabled,
        isInvalid: isInvalid && !isDisabled,
        onChange,
        value,
      } as const;

      return (
        <CardContextProvider value={{ isDisabled }}>
          <chakra.label
            htmlFor={id}
            ref={ref}
            {...containerProps}
            {...labelProps}
          >
            {asideSlot}
            {contentSlot}
            <Form.Checkbox {...checkboxProps} tabIndex={-1} />
          </chakra.label>
        </CardContextProvider>
      );
    }

    /**
     * "radio" variant
     * renders a Form.Radio by default in the "action" slot
     */
    if (props.variant === 'radio') {
      const { id, isChecked, onChange, value, ...labelProps } = props;

      const radioProps = {
        id,
        isChecked,
        isDisabled,
        onChange,
        value,
      } as const;

      return (
        <CardContextProvider value={{ isDisabled }}>
          <chakra.label ref={ref} {...containerProps} {...labelProps}>
            {asideSlot}
            {contentSlot}
            <Form.Radio {...radioProps} isFocusable={false} />
          </chakra.label>
        </CardContextProvider>
      );
    }

    /**
     * "switch" variant
     * renders a Form.SunshineSwitch by default in the "action" slot
     */
    if (props.variant === 'switch') {
      const { defaultChecked, id, isChecked, onChange, value, ...labelProps } =
        props;

      const switchProps = {
        defaultChecked,
        id,
        isChecked,
        isDisabled,
        onChange,
        value,
      } as const;

      return (
        <CardContextProvider value={{ isDisabled }}>
          <chakra.label ref={ref} {...containerProps} {...labelProps}>
            {asideSlot}
            {contentSlot}
            <Form.SunshineSwitch
              {...switchProps}
              onFocus={(e) => {
                e.preventDefault();
              }} // We found no other way to override the outline on focus on the switch button - switch is not accessible anyway
              tabIndex={-1}
            />
          </chakra.label>
        </CardContextProvider>
      );
    }

    return (
      <CardContextProvider value={{ isDisabled }}>
        <chakra.div
          ref={ref}
          {...props}
          {...containerProps}
          onClick={isDisabled ? undefined : props.onClick}
        >
          {asideSlot}
          {contentSlot}
          {actionSlot}
        </chakra.div>
      </CardContextProvider>
    );
  },
) as FC<SunshineCardProps> & CardParts;

SunshineCard.Button = CardButton;
SunshineCard.CheckboxGroup = CardCheckboxGroup;
SunshineCard.Content = CardContent;
SunshineCard.Group = CardGroup;
SunshineCard.RadioGroup = CardRadioGroup;
SunshineCard.Slot = CardSlot;
SunshineCard.SunshineIcon = CardSunshineIcon;
SunshineCard.Text = CardText;
SunshineCard.Title = CardTitle;

export default SunshineCard;
