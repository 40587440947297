import { createMachine } from 'xstate';

import shared from 'common/bento/shared';
import { type AnyMachineEvent } from 'common/bento/types/Abstract';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';

import actions from './actions';
import guards, { Guard } from './guards';
import { type Context } from './model';
import services, { Service } from './services';

export enum State {
  INIT = 'init',

  ASSIGNMENT_SEND = 'assignment-send',

  DOCUMENT_INIT = 'document-init',

  DOCUMENT = 'document',
  AGREEMENT_SEND = 'agreement-send',

  DOCUMENT_SIGNATURE = 'document-signature',
  COMPLETE_AGREEMENT = 'complete-agreement',

  REVIEW = 'review',

  ABORT = 'abort',
  DONE = 'done',
}

const machine = createMachine<Context, AnyMachineEvent>(
  {
    id: 'agreement',

    initial: State.INIT,

    on: {
      '*': {
        actions: shared.actions.unhandled(),
      },
    },

    states: {
      [State.INIT]: {
        always: [
          {
            cond: Guard.HasSignedAgreement,
            target: State.REVIEW,
          },
          {
            target: State.ASSIGNMENT_SEND,
          },
        ],
      },

      [State.ASSIGNMENT_SEND]: {
        always: {
          cond: Guard.IsDocumentAssigned,
          target: State.DOCUMENT_INIT,
        },

        invoke: {
          id: 'sendAssignment',
          onDone: {
            target: State.DOCUMENT_INIT,
          },

          onError: {
            actions: shared.actions.error(),
            target: State.ABORT,
          },
          src: Service.SendAssignment,
        },
      },

      [State.DOCUMENT_INIT]: {
        always: [
          {
            cond: Guard.IsSignatureRequired,
            target: State.DOCUMENT_SIGNATURE,
          },
          {
            target: State.DOCUMENT,
          },
        ],
      },

      [State.DOCUMENT]: {
        entry: shared.actions.trackView(),

        on: {
          NEXT: State.AGREEMENT_SEND,
          PREV: {
            actions: shared.actions.track({ name: 'Agreement_Check_Failed' }),
            target: State.ABORT,
          },
        },
      },

      [State.AGREEMENT_SEND]: {
        invoke: {
          id: 'sendAgreement',
          onDone: {
            actions: shared.actions.track({
              name: 'Agreement_Check_Succeeded',
            }),
            target: State.DONE,
          },

          onError: {
            actions: shared.actions.error(),
            target: State.DOCUMENT,
          },
          src: Service.SendAgreement,
        },
      },

      [State.DOCUMENT_SIGNATURE]: {
        entry: shared.actions.trackView(),

        on: {
          NEXT: State.COMPLETE_AGREEMENT,
          PREV: {
            actions: shared.actions.track({
              name: 'Agreement_Check_Failed',
            }),
            target: State.ABORT,
          },
        },
      },

      [State.COMPLETE_AGREEMENT]: {
        invoke: {
          id: 'completeAgreement',
          onDone: {
            actions: shared.actions.track({
              name: 'Agreement_Check_Succeeded',
            }),
            target: State.DONE,
          },
          onError: {
            actions: shared.actions.error(),
            target: State.DOCUMENT_SIGNATURE,
          },
          src: Service.CompleteAgreement,
        },
      },

      [State.REVIEW]: {
        entry: shared.actions.trackView(),

        on: {
          NEXT: State.DONE,
          PREV: State.ABORT,
        },
      },

      [State.ABORT]: {
        data: {
          status: BentoModuleDoneStatus.ABORT,
        },
        type: 'final',
      },

      [State.DONE]: {
        data: {
          status: BentoModuleDoneStatus.DONE,
        },
        type: 'final',
      },
    },
  },
  {
    actions,
    guards,
    services,
  },
);

export default machine;
